import { css } from '@emotion/css';
import { Camera } from '../../../api/types';
import { colors } from '../../../constants/colors';

type CameraTabsProps = {
  cameras: Camera[];
  currentCamera?: Camera;
  isMwos: boolean;
  onClickTab: (c?: Camera) => void;
};

export default function CameraTabs({
  cameras,
  currentCamera,
  isMwos,
  onClickTab,
}: CameraTabsProps) {
  const directionKey = isMwos ? 'directionText' : 'cameraDirection';
  return (
    <nav className={styles}>
      <ul>
        <li
          className={currentCamera === undefined ? 'active' : undefined}
          onClick={() => onClickTab()}
        >
          <label>All</label>
        </li>
        {cameras.map((c, i) => (
          <li
            key={`cam-${i}`}
            className={
              currentCamera && currentCamera[directionKey] === c[directionKey]
                ? 'active'
                : undefined
            }
            onClick={() => onClickTab(c)}
          >
            <label>{c[directionKey]}</label>
          </li>
        ))}
      </ul>
    </nav>
  );
}

const styles = css({
  backgroundColor: '#ffffffdd',
  paddingBottom: '1em',
  ul: {
    borderBottom: '1px solid #aaa',
    listStyle: 'none',
    display: 'flex',
    flexDirection: 'row',
    padding: '0 .75em',
    li: {
      paddingBottom: '.35em',
      label: {
        display: 'block',
        padding: '.25em .5em',
        fontSize: '1.3em',
        fontWeight: 500,
        color: '#333',
        cursor: 'pointer',
        borderRadius: 5,
        ':hover': {
          color: '#111',
          backgroundColor: '#0000000d',
        },
      },
      marginRight: '1em',
      '&.active': {
        borderBottom: `4px solid ${colors.primaryLight}`,
        label: {
          color: '#111',
          fontWeight: 600,
        },
      },
    },
  },
});
