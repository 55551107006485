import { DateTime } from 'luxon';
import mwosIcon from '../../../assets/images/mwos.svg';
import ifr from '../../../assets/images/station_ifr.svg';
import ifrCamera from '../../../assets/images/station_ifr_cam.svg';
import lifr from '../../../assets/images/station_lifr.svg';
import lifrCamera from '../../../assets/images/station_lifr_cam.svg';
import mvfr from '../../../assets/images/station_mvfr.svg';
import mvfrCamera from '../../../assets/images/station_mvfr_cam.svg';
import plain from '../../../assets/images/station_plain.svg';
import plainCamera from '../../../assets/images/station_plain_cam.svg';
import vfr from '../../../assets/images/station_vfr.svg';
import vfrCamera from '../../../assets/images/station_vfr_cam.svg';
import { FlightCategory } from '../../../types/types';

export const minutesAgo = (datetime: string) => {
  const now = DateTime.now();
  const then = DateTime.fromISO(datetime);
  const minutesAgo = Math.round(now.diff(then).as('minutes'));
  return minutesAgo;
};

export const displayDatetime = (datetime: string) => {
  const then = DateTime.fromISO(datetime);
  return then.toLocaleString(DateTime.DATETIME_SHORT);
};

type getStationIconProps = {
  stationType: 'mwos' | 'faa_camera_site' | 'weather_station';
  flightCategory: FlightCategory;
};

export const getStationIcon = ({
  stationType,
  flightCategory,
}: getStationIconProps) => {
  let image;
  if (stationType === 'mwos') {
    return mwosIcon;
  }
  if (!flightCategory) {
    if (stationType === 'faa_camera_site') {
      image = plainCamera;
    } else {
      image = plain;
    }
  } else {
    if (stationType === 'faa_camera_site') {
      switch (flightCategory) {
        case 'VFR':
          image = vfrCamera;
          break;
        case 'IFR':
          image = ifrCamera;
          break;
        case 'MVFR':
          image = mvfrCamera;
          break;
        case 'LIFR':
          image = lifrCamera;
          break;
      }
    } else {
      switch (flightCategory) {
        case 'VFR':
          image = vfr;
          break;
        case 'IFR':
          image = ifr;
          break;
        case 'MVFR':
          image = mvfr;
          break;
        case 'LIFR':
          image = lifr;
          break;
      }
    }
  }
  return image;
};
