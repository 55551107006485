import { css } from '@emotion/css';
import { useCallback, useEffect, useState } from 'react';

import { saveUserRoute } from '../../../api';
import { LegacyRoute, saveUserRouteLegacy } from '../../../api/legacy';
import { StationDetails } from '../../../api/types';
import useStore from '../../../state/state';
import { Aircraft, LatLng, MapUndoAction, Route } from '../../../types/types';
import IconCheckmarkCircleOutline from '../../svg-icons/Checkmark';
import IconClose from '../../svg-icons/Close';
import AdsbButton from './AdsbButton';
import AdsbDrawer from './AdsbDrawer';
import Button from './Button';
import Compass from './Compass';
import CorriderSlider from './CorridorSlider';
import LockButton from './LockButton';
import MapSettingsDrawer from './MapSettingsDrawer';
import MenuButton from './MenuButton';
import ResetButton from './ResetButton';
import RouteInfo from './RouteInfo';
import RoutesButton from './RoutesButton';
import RoutesDrawer from './RoutesDrawer';
import SearchButton from './SearchButton';
import StationsButton from './StationsButton';
import StationsDrawer from './StationsDrawer';
import StatusMessage from './StatusMessage';
import UndoButton from './UndoButton';

type Props = {
  route: Route;
  setRoute: (r: Route) => void;
  setAircraft: {
    set: (a: object[]) => void;
  };
  undoActions: MapUndoAction[];
  flyTo: (position: LatLng) => void;
};

export default function Controls({
  route,
  setRoute,
  setAircraft,
  setCorridor,
  undo,
  undoActions,
  flyTo,
  measureRoute,
  setRouteLocked,
  setHeading,
}: Props) {
  console.log('Controls:undoActions', undoActions);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const setGlobalAircraft = useStore((store) => store.setAircraft);
  const mapSettings = useStore((store) => store.mapSettings);
  const setMapSettings = useStore((store) => store.setMapSettings);
  const [isAdsbDrawerOpen, setIsAdsbDrawerOpen] = useState<boolean>(false);
  const [isRoutesDrawerOpen, setIsRoutesDrawerOpen] = useState<boolean>(false);
  const [isStationsDrawerOpen, setIsStationsDrawerOpen] =
    useState<boolean>(false);

  const routeState = useStore((store) => store.routeState);
  const setRouteState = useStore((store) => store.setRouteState);
  const setUserSavedRoutes = useStore((store) => store.setUserSavedRoutes);
  const setStatusMessage = useStore((store) => store.setStatusMessage);

  const openMenu = useCallback(() => {
    setIsMenuOpen(true);
  }, []);

  useEffect(() => {
    const adsbInterval = setInterval(async () => {
      const data = await fetch('https://pano.montiscorp.com/adsb/data.json');
      // const data = await fetch('http://10.10.0.6:8081/data.json');
      const json = await data.json();
      const rampart = await fetch(
        'https://pano.montiscorp.com/rampart/adsb/data.json'
      );
      json.push(...(await rampart.json()));
      // console.log(json);
      const aircraft = json.map((a) => ({
        lat: a.lat,
        lon: a.lon,
        flight: a.flight,
        alt_baro: a.altitude,
        alt_geom: a.altitude,
      }));
      setAircraft.set(aircraft);
      setGlobalAircraft(aircraft);
    }, 5e3);
    return () => {
      clearInterval(adsbInterval);
    };
  });

  const onPlaneButtonClick = useCallback(async () => {
    // const aircraft = await getAircraft(position, 10);
    // setAircraft.set(aircraft.ac);
    setIsAdsbDrawerOpen(true);
  }, []);

  const onStationsButtonClick = useCallback(async () => {
    setIsStationsDrawerOpen(true);
  }, []);

  const onCorridorSliderChange = useCallback(
    (value) => {
      setCorridor.set({ ...route, corridor: value });
      // setRoute({ ...route, corridor: value });
    },
    [route, setCorridor]
  );

  // const [locked, setLocked] = useState<boolean>(routeState.locked);
  const onLockButtonClick = useCallback(() => {
    const isLocked = routeState.locked ? false : true;
    // setLocked(isLocked);
    setRouteLocked(isLocked);
    setRouteState({ ...routeState, locked: isLocked });
  }, [setRouteLocked, routeState]);

  const flyToSearchResult = useCallback(
    (result: LatLng) => {
      flyTo(result);
    },
    [flyTo]
  );

  const selectRoute = useCallback(
    (r: LegacyRoute) => {
      setRoute({
        path: r.Path.map((p) => ({
          latitude: p.Latitude,
          longitude: p.Longitude,
        })),
        corridor: r.Corridor,
      });
    },
    [setRoute]
  );

  const selectStation = useCallback(
    (s: StationDetails) => {
      console.log(s);
      flyTo({
        latitude: s.latitude,
        longitude: s.longitude,
      });
    },
    [flyTo]
  );

  const onResetButtonClick = useCallback(() => {
    setRouteState({ ...routeState, route: { path: [], corridor: 35 } });
  }, [routeState]);

  const onSaveButtonClick = useCallback(async () => {
    try {
      setStatusMessage('Saving route...');
      await saveUserRoute({
        routeName: routeState.name as string,
        path: routeState.route.path,
        corridorWidthMeters: routeState.route.corridor,
      });
      await saveUserRouteLegacy({
        Name: routeState.name as string,
        Path: routeState.route.path.map((p) => ({
          Latitude: p.latitude,
          Longitude: p.longitude,
        })),
        Corridor: routeState.route.corridor,
      });
      setStatusMessage('Route saved successfully');
      setUserSavedRoutes([]);
    } catch (err) {
      console.error('error saving route', err);
    } finally {
      setTimeout(() => setStatusMessage(undefined), 2e3);
    }
  }, [routeState]);

  const onCancelButtonClick = useCallback(() => {
    setRouteState({
      editing: false,
      name: undefined,
      locked: false,
      isNewRoute: false,
      route: {
        path: [],
        corridor: 35,
      },
    });
  }, []);

  const selectAircraft = useCallback(
    (a: Aircraft) => {
      flyTo({
        latitude: a.lat,
        longitude: a.lon,
      });
    },
    [flyTo]
  );

  return (
    <>
      <div className={styles.leftControls}>
        <RoutesButton onClick={() => setIsRoutesDrawerOpen(true)} />
        <StationsButton onClick={onStationsButtonClick} />
        <AdsbButton onClick={onPlaneButtonClick} />
      </div>

      <div className={styles.topControls}>
        <SearchButton onClick={undefined} onResultClick={flyToSearchResult} />
        {/* <MeasureButton onClick={measureRoute} /> */}
        <MenuButton onClick={openMenu} />
      </div>

      {routeState.name !== undefined && (
        <>
          <RouteInfo routeName={routeState.name} />
          <div className={styles.routeControls}>
            {routeState.route.path.length > 1 && (
              <Button
                icon={<IconCheckmarkCircleOutline />}
                text="Save"
                onClick={onSaveButtonClick}
              />
            )}
            {undoActions.length > 0 && !routeState.locked && (
              <UndoButton onClick={undo} />
            )}
            <LockButton
              isLocked={routeState.locked}
              onClick={onLockButtonClick}
            />
            {!routeState.locked && <ResetButton onClick={onResetButtonClick} />}
            <Button
              icon={<IconClose />}
              text="Close"
              onClick={onCancelButtonClick}
            />
          </div>
          {routeState.route.path.length > 0 && !routeState.locked && (
            <CorriderSlider
              value={route.corridor}
              onChange={onCorridorSliderChange}
            />
          )}
        </>
      )}

      <MapSettingsDrawer
        isOpen={isMenuOpen}
        close={() => setIsMenuOpen(false)}
        mapSettings={mapSettings}
        setMapSettings={setMapSettings}
      />

      <AdsbDrawer
        isOpen={isAdsbDrawerOpen}
        onClose={() => setIsAdsbDrawerOpen(false)}
        onSelect={selectAircraft}
      />
      <StationsDrawer
        isOpen={isStationsDrawerOpen}
        onClose={() => setIsStationsDrawerOpen(false)}
        onSelect={selectStation}
      />
      <RoutesDrawer
        isOpen={isRoutesDrawerOpen}
        onClose={() => setIsRoutesDrawerOpen(false)}
        onSelect={selectRoute}
      />

      <StatusMessage />
      <Compass onClick={() => setHeading(0)} />
    </>
  );
}

const styles = {
  topControls: css({
    position: 'absolute',
    top: 10,
    right: 5,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    '> div': {
      marginRight: '.25rem',
    },
  }),
  leftControls: css({
    top: 10,
    left: 10,
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    '> div': {
      marginBottom: '.25rem',
    },
  }),
  routeControls: css({
    position: 'absolute',
    top: 85,
    right: 5,
    display: 'flex',
    flexDirection: 'row',
    '> div': {
      marginRight: '.25rem',
    },
    '.name-input': {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#ffffffdd',
      borderRadius: 5,
      padding: '.25em .5em',
      input: {
        outline: 'none',
        border: 'none',
      },
    },
  }),
};
