import { css } from '@emotion/css';
import { useCallback, useEffect } from 'react';
import { memoGetUserStations } from '../../../api';
import { Station, UserStation } from '../../../api/types';
import stationIcon from '../../../assets/images/station_light.svg';
import { colors } from '../../../constants/colors';
import useStore from '../../../state/state';
import Drawer from '../../Drawer';
import IconBookmarkStarFill from '../../svg-icons/Star';
import { getStationIcon } from '../station/functions';

type StationsListProps = {
  stations: UserStation[];
  onSelect: (s: UserStation) => void;
};

const StationsList = ({ stations, onSelect }: StationsListProps) => {
  return (
    <ul>
      {stations.map((s) => (
        <li
          key={s.id}
          onClick={() => {
            onSelect(s);
          }}
        >
          <StationIcon station={s} />
          <StationLabel station={s} />
        </li>
      ))}
    </ul>
  );
};

const StationIcon = ({ station: s }) => {
  return <img src={getStationIcon(s)} height={24} width={24} />;
};

const StationLabel = ({ station: s }: { station: UserStation }) => {
  if (s.icaoId) {
    return <label>{`${s.siteName} (${s.icaoId})`}</label>;
  }
  return <label>{s.siteName}</label>;
};

type StationsDrawerProps = {
  isOpen: boolean;
  onClose: () => void;
  onSelect: (s: Station) => void;
};

export default function StationsDrawer({
  isOpen,
  onClose,
  onSelect,
}: StationsDrawerProps) {
  const userSavedStations = useStore((store) => store.userSavedStations);
  const setUserSavedStations = useStore((store) => store.setUserSavedStations);

  const load = useCallback(async () => {
    const s = await memoGetUserStations();
    if (s?.length > 0) {
      s.sort((a, b) => a.displayOrder - b.displayOrder);
      setUserSavedStations(s);
    }
  }, []);

  useEffect(() => {
    load();
    const loadInterval = setInterval(load, 60e3);
    return () => clearInterval(loadInterval);
  }, []);

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      icon={<img src={stationIcon} style={{ width: '1em' }} />}
      title="Saved Stations"
      screenSide="left"
      className={style}
    >
      <span className="info">
        <IconBookmarkStarFill
          color={colors.primaryLight}
          width="2em"
          height="2.5em"
        />
        You can save stations using the bookmark icon in station windows.
      </span>
      {userSavedStations.length > 0 ? (
        <StationsList stations={userSavedStations} onSelect={onSelect} />
      ) : (
        <span>Loading stations...</span>
      )}
    </Drawer>
  );
}

const style = css({
  '.title': {
    fontSize: '1.5em',
    fontWeight: 600,
  },
  '.info': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    svg: {
      marginRight: '.75em',
    },
    padding: '0.5em 0',
    borderTop: '1px solid #ccc',
    borderBottom: '1px solid #ccc',
  },
  ul: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
    marginTop: 10,
    li: {
      fontWeight: 400,
      margin: 0,
      fontSize: '1.15em',
      marginBottom: 5,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      padding: '.2em .25em',
      cursor: 'pointer',
      label: {
        cursor: 'pointer',
      },
      ':hover': {
        backgroundColor: '#0000000a',
        color: colors.primaryLight,
      },
      img: {
        marginRight: 10,
      },
    },
  },
});
