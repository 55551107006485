import { createRoot } from 'react-dom/client';
import App from './App';

// The URL on your server where CesiumJS's static files are hosted.
window.CESIUM_BASE_URL = '/cesium';

// eslint-disable-next-line no-undef
if (module.hot) {
  // eslint-disable-next-line no-undef
  module.hot.accept();
}

// const main = () => {
const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);
// };

// main();
