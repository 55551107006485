import {
  Authenticator,
  ThemeProvider,
  useAuthenticator,
} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { css } from '@emotion/css';
import { useEffect } from 'react';
import { Navigate, redirect } from 'react-router-dom';
import planeBgImage from '../assets/hero.avif';
import { colors } from '../constants/colors';

export default function SignIn() {
  // const theme = useTheme();

  // theme.tokens.components.button.backgroundColor = colors.primaryLight;
  // theme.tokens.components.button.color = 'white';
  // theme.tokens.colors.primary = { 80: colors.primaryLight };

  const { authStatus } = useAuthenticator((context) => [context.authStatus]);

  useEffect(() => {
    if (authStatus === 'authenticated') {
      redirect('/map');
    }
  }, [authStatus]);

  return (
    <ThemeProvider
      theme={{
        tokens: {
          colors: {
            primary: {
              // Used for Sign In button and Forgot Password
              80: colors.primaryLight,
            },
          },
        },
      }}
    >
      <div className={style}>
        <Authenticator hideSignUp={true}>
          {/* {({ signOut, user }) => <div>{JSON.stringify(user)}</div>} */}
          {({ signOut, user }) => <Navigate to="/map" replace={true} />}
        </Authenticator>
      </div>
    </ThemeProvider>
  );
}

const style = css({
  display: 'flex',
  position: 'absolute',
  width: '100%',
  height: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  background: `url(${planeBgImage}) no-repeat center center fixed`,
  backgroundSize: 'cover',
  '*': {
    fontFamily: 'Open Sans',
  },
});
