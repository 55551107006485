import { css } from '@emotion/css';
import { useMemo } from 'react';
import { StationDetails } from '../../../api/types';
import { colors } from '../../../constants/colors';

type Props = {
  station: StationDetails;
};

export default function WeatherString({ station }: Props) {
  const weatherString =
    station.observations?.length > 0
      ? station.observations[0].rawText
      : station.metar?.rawText;

  const wxStringColor = useMemo(() => {
    if (!station.metar?.flightCategory) {
      return '#333';
    }
    switch (station.metar.flightCategory) {
      case 'IFR':
        return colors.ifr;
      case 'VFR':
        return colors.vfr;
      case 'MVFR':
        return colors.mvfr;
      case 'LIFR':
        return colors.lifr;
    }
  }, [station.metar]);

  return (
    <div className={styles} style={{ color: wxStringColor }}>
      {weatherString}
    </div>
  );
}

const styles = css({
  backgroundColor: '#ffffffdd',
  padding: '0 .75em',
  paddingBottom: '.5em',
  fontSize: '1.45em',
  fontWeight: '600',
  '@media (max-width: 600px)': {
    fontSize: '.75em',
  },
});
