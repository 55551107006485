import { useAuthenticator } from '@aws-amplify/ui-react';
import { useEffect } from 'react';
import { Navigate, redirect } from 'react-router-dom';
import Map from '../components/map/MapView';

export default function Main() {
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);

  useEffect(() => {
    if (authStatus !== 'authenticated') {
      redirect('/');
    }
  }, [authStatus]);

  if (authStatus !== 'authenticated') {
    return <Navigate to="/" replace={true} />;
  }
  if (authStatus === 'authenticated') {
    return <Map />;
  }
  return false;
}
