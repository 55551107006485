import { css } from '@emotion/css';
import { CSSProperties, ReactNode } from 'react';
import { colors } from '../constants/colors';

type Props = {
  text?: string;
  icon?: ReactNode;
  children?: ReactNode;
  backgroundColor?: string;
  color?: string;
  primary: boolean;
  style?: CSSProperties;
  onClick: () => void;
};

export default function Button({
  icon,
  text,
  children,
  backgroundColor,
  color,
  primary,
  style,
  onClick,
}: Props) {
  if (primary) {
    backgroundColor = colors.primaryLight;
    color = 'white';
  }
  return (
    <button
      className={styles}
      style={{ backgroundColor, color, ...style }}
      onClick={onClick}
    >
      {icon} {text}
    </button>
  );
}

const styles = css({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  outline: 'none',
  border: 'none',
  padding: '.35em .5em',
  borderRadius: 5,
  fontSize: '1.1em',
  fontWeight: 500,
  ':hover': {
    opacity: 0.9,
    cursor: 'pointer',
  },
});
