// icon:plane-up | Fontawesome https://fontawesome.com/ | Fontawesome
function IconPlaneUp(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 512 512"
      fill="currentColor"
      height="1em"
      width="1em"
      {...props}
    >
      <path d="M192 93.7C192 59.5 221 0 256 0c36 0 64 59.5 64 93.7V160l177.8 118.5c8.9 5.9 14.2 15.9 14.2 26.6v56.7c0 10.9-10.7 18.6-21.1 15.2L320 320v80l57.6 43.2c4 3 6.4 7.8 6.4 12.8v42c0 7.8-6.3 14-14 14-1.3 0-2.6-.2-3.9-.5L256 480l-110.1 31.5c-1.3.4-2.6.5-3.9.5-7.8 0-14-6.3-14-14v-42c0-5 2.4-9.8 6.4-12.8L192 400v-80L21.1 377C10.7 380.4 0 372.7 0 361.8v-56.7c0-10.7 5.3-20.7 14.2-26.6L192 160V93.7z" />
    </svg>
  );
}

export default IconPlaneUp;
