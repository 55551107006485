import { css } from '@emotion/css';
import {
  Cartesian2,
  Cartesian3,
  Cartographic,
  Color,
  createGooglePhotorealistic3DTileset,
  CustomDataSource,
  Ellipsoid,
  Entity,
  EntityCollection,
  Ion,
  JulianDate,
  RequestScheduler,
  ScreenSpaceEventHandler,
  ScreenSpaceEventType,
  Terrain,
  Viewer,
} from 'cesium';

import { useCallback, useEffect, useRef, useState } from 'react';

import centerOfMass from '@turf/center-of-mass';
import distance from '@turf/distance';
import { lineString, point } from '@turf/helpers';
import { SiteDetails, Station } from '../../api/types';
import useStore, { RouteState } from '../../state/state';
import {
  Aircraft,
  FlightCategory,
  LatLng,
  LatLngAlt,
  MapSettings,
  MapUndoAction,
  MapUndoActionType,
  Route,
} from '../../types/types';
import {
  createAircraftEntity,
  createAirportEntity,
  createMwosEntity,
  createRouteCorridorEntity,
  createRouteEntity,
  createRoutePointEntity,
  createStationEntity,
  entityPosInDegrees,
  screenSpaceCoordToDeg,
} from './cesium';
import {
  executeUndoAction,
  getScreenAirports,
  getScreenMwos,
  getScreenSites,
  getScreenStations,
  refreshAndDrawRouteAirports,
  refreshAndDrawRouteMwos,
  refreshAndDrawRouteStations,
} from './map-functions';
import StationModal from './StationModal';

type MapProps = {
  route: Route;
  setRoute: (r: Route) => void;
  mapSettings: MapSettings;
  setSetAircraft: (f: { set: (a: Aircraft[]) => void }) => void;
  setSetCorridor: (f: { set: (r: Route) => void }) => void;
  setDoUndo: (f: { do: (a: MapUndoAction) => void }) => void;
  setUndoActions: (a: MapUndoAction[]) => void;
  setFlyTo: (f: { do: (p: LatLng) => void }) => void;
  setDoMeasureRoute: (f: { do: () => void }) => void;
  setSetRoute: (f: { set: (r: Route) => void }) => void;
  setSetLocked: (f: { set: (l: boolean) => void }) => void;
  setSetHeading: (f: { set: (h: number) => void }) => void;
};

export default function Map({
  route,
  setRoute: _,
  setSetAircraft,
  setSetCorridor,
  setDoUndo,
  setUndoActions,
  setFlyTo,
  setDoMeasureRoute,
  setSetRoute,
  setSetLocked,
  setSetHeading,
}: MapProps) {
  const cesiumRef = useRef<HTMLDivElement>(null);

  const [modalStation, setModalStation] = useState(null);
  const [viewer, setViewer] = useState<Viewer>();
  const mapSettings = useStore((store) => store.mapSettings);
  const setHeading = useStore((store) => store.setHeading);
  const routeState = useStore((store) => store.routeState);
  const setRouteState = useStore((store) => store.setRouteState);

  console.log('render:Map', { routeState });

  // const setRoute = useCallback(
  //   (r: Route) => {
  //     console.log('setRoute', { routeState, r });
  //     setRouteState({
  //       ...routeState,
  //       route: { path: r.path, corridor: r.corridor },
  //     });
  //   },
  //   [routeState.editing, routeState.locked, routeState.name]
  // );

  const closeStationModal = useCallback(() => {
    setModalStation(null);
    if (viewer) {
      viewer.selectedEntity = undefined;
    }
  }, [viewer]);

  useEffect(() => {
    if (!cesiumRef.current) {
      return;
    }
    const cesiumRoot = cesiumRef.current;

    const onEntitySelected = (entity?: Entity) => {
      if (!entity || !entity.properties) {
        return;
      }
      const type = entity.properties.entityType.valueOf();
      if (type === 'MWOS' || type === 'AWOS') {
        setModalStation(entity.properties.stationDetails.valueOf());
      }
    };

    // const stats = new Stats();
    // stats.showPanel(0);
    // document.body.appendChild(stats.dom);
    // stats.dom.className = 'stats';

    const cesium = initCesium({
      container: cesiumRoot,
      onEntitySelected,
      setRouteState,
      setUndoActions,
      mapSettings,
      setHeading,
      routeState,
    });

    setViewer(cesium.viewer);
    setSetAircraft({ set: cesium.setAircraft });
    setSetCorridor({ set: cesium.setCorridor });
    setDoUndo({ do: cesium.undo });
    setFlyTo({ do: cesium.flyTo });
    setDoMeasureRoute({ do: cesium.measure });
    setSetRoute({ set: cesium.setRoute });
    setSetLocked({ set: cesium.setLocked });
    setSetHeading({ set: cesium.setHeading });

    const unsubMapSettings = useStore.subscribe(
      (state) => state.mapSettings,
      (mapSettings) => {
        cesium.setMapSettings(mapSettings);
      }
    );
    const unsubRouteState = useStore.subscribe(
      (state) => state.routeState,
      (routeState, prevRouteState) => {
        // if (
        //   JSON.stringify(routeState.route) !==
        //     JSON.stringify(prevRouteState.route) ||
        //   routeState.locked !== prevRouteState.locked
        // ) {
        cesium.setRouteState(routeState);
        // }
      }
    );

    return () => {
      unsubMapSettings();
      unsubRouteState();
      cesium.dispose();
    };
  }, []);

  return (
    <>
      <div id="cesium-root" className={styles} ref={cesiumRef}></div>;
      <StationModal
        isVisible={modalStation !== null}
        close={closeStationModal}
        station={modalStation}
      />
    </>
  );
}

const styles = css({
  position: 'absolute',
  height: '100svh',
  width: '100svw',
  overflow: 'hidden',
});

type InitCesiumProps = {
  container: HTMLDivElement;
  onEntitySelected: (e: Entity) => void;
  setRouteState: (s: RouteState) => void;
  setUndoActions: (a: MapUndoAction[]) => void;
  mapSettings: MapSettings;
  routeState: RouteState;
};

type Cesium = {
  dispose: () => void;
  viewer: Viewer;
  setAircraft: (a: Aircraft[]) => void;
  setCorridor: (r: Route) => void;
  undo: (u: MapUndoAction) => void;
  flyTo: (p: LatLng) => void;
  measure: () => void;
  // setRoute: (r: Route) => void;
  setLocked: (l: boolean) => void;
  setMapSettings: (s: MapSettings) => void;
  setHeading: (h: number) => void;
  setRouteState: (s: RouteState) => void;
};

type RouteEntities = {
  points: Entity[];
  line?: Entity;
  corridor?: Entity;
};

type MapState = {
  // route: {
  //   path: LatLngAlt[];
  //   corridor: number;
  // };
  routeEntities: RouteEntities;
  routeState: RouteState;
  stations: Station[];
  stationEntities: Entity[];
  airports: [];
  airportEntities: Entity[];
  mwos: [];
  mwosEntities: Entity[];
  undoActions: MapUndoAction[];
  aircraft: [];
  aircraftEntities: Entity[];
  dragging: {
    entity?: Entity;
    startPoint?: LatLngAlt;
    endPoint?: LatLngAlt;
  };
  mapSettings: MapSettings;
};

const useGoogleTiles = window.location.href.match(/google=true/g);

const initCesium = ({
  container,
  onEntitySelected,
  setRouteState,
  setUndoActions,
  mapSettings,
  setHeading,
  routeState,
}: InitCesiumProps): Cesium => {
  Ion.defaultAccessToken =
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI5MmU4YjFlMS1iZGM1LTQxZDktYjk0MS1lZWFkYjQwOGUxYTQiLCJpZCI6ODY0MSwiaWF0IjoxNzIyNjM0MzIxfQ.72B8HNc_7WdCogDSnS7ztJmWJy1jLsz2y5DF-jqVCIQ';

  RequestScheduler.requestsByServer['tile.googleapis.com:443'] = 18;

  const viewer = new Viewer(container, {
    // terrain: useGoogleTiles ? undefined : Terrain.fromWorldTerrain(),
    // globe: useGoogleTiles ? false : undefined,
    // globe
    // terrain: Terrain.fromWorldTerrain(),
    // skyBox: SkyBox.createEarthSkyBox(),
    navigationHelpButton: false,
    sceneModePicker: false,
    infoBox: false,
    homeButton: false,
    baseLayerPicker: false,
    animation: false,
    fullscreenButton: false,
    timeline: false,
    geocoder: false,
    vrButton: false,
    // requestRenderMode: true,
  });
  console.log('window.devicePixelRatio', window.devicePixelRatio);
  // viewer.resolutionScale = window.devicePixelRatio;
  if (window.outerWidth < 800) {
    viewer.useBrowserRecommendedResolution = false;
    viewer.resolutionScale = 0.35;
  } else {
    viewer.useBrowserRecommendedResolution = false;
    viewer.resolutionScale = 1;
  }
  viewer.scene.postProcessStages.fxaa.enabled = true;
  viewer.scene.msaaSamples = 4;

  if (!useGoogleTiles) {
    viewer.scene.setTerrain(
      Terrain.fromWorldTerrain({
        requestVertexNormals: true,
        requestWaterMask: false,
      })
    );
    viewer.scene.globe.enableLighting = true;
  }

  const init3DTileset = async () => {
    try {
      const tileset = await createGooglePhotorealistic3DTileset(undefined, {
        maximumScreenSpaceError: 48,
      });
      viewer.scene.primitives.add(tileset);
    } catch (error) {
      console.error('failed to load tileset', error);
    }
  };
  if (useGoogleTiles) {
    init3DTileset();
  }

  // Merrill Field MWOS
  const position = {
    latitude: 61.21675013797042,
    longitude: -149.83343375728631,
  };
  viewer.camera.setView({
    destination: Cartesian3.fromDegrees(
      position.longitude,
      position.latitude,
      1e5
    ),
  });

  const mapState: MapState = {
    // route: {
    //   path: [],
    //   corridor: 35,
    // },
    routeEntities: {
      points: [],
      line: undefined,
      corridor: undefined,
    },
    routeState: { ...routeState },
    stations: [],
    stationEntities: [],
    airports: [],
    airportEntities: [],
    mwos: [],
    mwosEntities: [],
    undoActions: [],
    aircraft: [],
    aircraftEntities: [],
    dragging: {
      entity: undefined,
      startPoint: undefined,
      endPoint: undefined,
    },
    mapSettings,
  };

  const camCtrl = viewer.scene.screenSpaceCameraController;
  const dragHandler = new ScreenSpaceEventHandler(viewer.canvas);
  dragHandler.setInputAction(
    (event: ScreenSpaceEventHandler.PositionedEvent) => {
      if (mapState.routeState.locked) {
        return;
      }
      const pickResult = viewer.scene.pick(event.position);
      if (!pickResult) {
        return;
      }
      if (!pickResult.id) {
        return;
      }
      const entity = pickResult.id as Entity;

      if (entity.properties?.entityType?.valueOf() !== 'ROUTE_POINT') {
        return;
      }

      const degPos = entityPosInDegrees(entity);
      if (degPos === undefined) {
        return;
      }

      const routePoint = mapState.routeState.route.path.find(
        (p) =>
          distance(
            [degPos.longitude, degPos.latitude],
            [p.longitude, p.latitude],
            { units: 'meters' }
          ) < 10
      );
      if (!routePoint) {
        return;
      }
      mapState.dragging.startPoint = routePoint;
      camCtrl.enableLook = false;
      camCtrl.enableRotate = false;
      camCtrl.enableTilt = false;
      camCtrl.enableTranslate = false;
      mapState.dragging.entity = entity;
    },
    ScreenSpaceEventType.LEFT_DOWN
  );

  dragHandler.setInputAction(({ endPosition }) => {
    if (!mapState.dragging.entity) {
      return;
    }
    const degPos = screenSpaceCoordToDeg(viewer, endPosition);
    if (degPos) {
      mapState.dragging.endPoint = degPos;
      if (!mapState.dragging.entity.id) {
        return;
      }
      const e = createRoutePointEntity(degPos, false);
      viewer.entities.remove(mapState.dragging.entity);
      mapState.dragging.entity = e;
      viewer.entities.add(e);
    }
  }, ScreenSpaceEventType.MOUSE_MOVE);

  dragHandler.setInputAction(() => {
    const { entity, startPoint, endPoint } = mapState.dragging;
    if (!entity || !startPoint || !endPoint) {
      return;
    }
    mapState.undoActions.push({
      type: MapUndoActionType.MoveRoutePoint,
      prev: {
        latitude: startPoint.latitude,
        longitude: startPoint.longitude,
        altitude: startPoint.altitude,
      },
      next: {
        latitude: endPoint.latitude,
        longitude: endPoint.longitude,
        altitude: startPoint.altitude,
      },
    });
    setUndoActions(mapState.undoActions);

    mapState.routeEntities.points.push(entity);
    startPoint.latitude = endPoint.latitude;
    startPoint.longitude = endPoint.longitude;
    mapState.dragging.entity = undefined;
    mapState.dragging.startPoint = undefined;
    mapState.dragging.endPoint = undefined;

    camCtrl.enableLook = true;
    camCtrl.enableRotate = true;
    camCtrl.enableTilt = true;
    camCtrl.enableTranslate = true;
    redrawRouteEntities();
    measureRoute();
  }, ScreenSpaceEventType.LEFT_UP);

  const handler = new ScreenSpaceEventHandler(viewer.canvas);
  handler.setInputAction((event) => {
    const result = viewer.scene.pick(event.position);

    if (result?.id?._id === mapState.routeEntities.corridor?.id) {
      viewer.selectedEntity = undefined;
    } else if (result?.id?.properties?.entityType?.valueOf() === 'AIRPORT') {
      viewer.selectedEntity = undefined;
    } else if (result?.id) {
      return;
    }

    if (mapState.routeState.editing === false || mapState.routeState.locked) {
      return;
    }

    const degPos = screenSpaceCoordToDeg(viewer, event.position);

    const newRoutePoint = createRoutePointEntity(degPos, false);
    mapState.routeEntities.points.push(newRoutePoint);
    viewer.entities.add(newRoutePoint);

    mapState.undoActions.push({
      type: MapUndoActionType.AddRoutePoint,
    });
    setUndoActions(mapState.undoActions);

    const z = newRoutePoint.position?.getValue(
      JulianDate.fromDate(new Date())
    )?.z;
    mapState.routeState.route.path.push({ ...degPos, altitude: z });

    if (mapState.routeState.route.path.length > 1) {
      const corridor = createRouteCorridorEntity(mapState.routeState.route);
      if (mapState.routeEntities.corridor) {
        viewer.entities.remove(mapState.routeEntities.corridor);
      }
      viewer.entities.add(corridor);
      mapState.routeEntities.corridor = corridor;

      const line = createRouteEntity(mapState.routeState.route);
      if (mapState.routeEntities.line) {
        viewer.entities.remove(mapState.routeEntities.line);
      }
      viewer.entities.add(line);
      mapState.routeEntities.line = line;

      fetchRouteData();
      measureRoute();

      setRouteState(mapState.routeState);
    }
  }, ScreenSpaceEventType.LEFT_CLICK);

  const redrawRouteEntities = () => {
    // Remove route entities
    if (mapState.routeEntities.line) {
      viewer.entities.remove(mapState.routeEntities.line);
    }
    for (const p of mapState.routeEntities.points) {
      viewer.entities.remove(p);
    }
    if (mapState.routeEntities.corridor) {
      viewer.entities.remove(mapState.routeEntities.corridor);
    }

    // If there is no route we're done
    if (mapState.routeState.route.path.length === 0) {
      return;
    }

    // Otherwise, redraw route entities using the current route
    // Route (line)
    const re = createRouteEntity(mapState.routeState.route);
    mapState.routeEntities.line = re;
    viewer.entities.add(re);

    // Points
    mapState.routeEntities.points = [];
    for (const p of mapState.routeState.route.path) {
      const e = createRoutePointEntity(p, mapState.routeState.locked);
      mapState.routeEntities.points.push(e);
      viewer.entities.add(e);
    }

    // Corridor
    const ce = createRouteCorridorEntity(mapState.routeState.route);
    mapState.routeEntities.corridor = ce;
    viewer.entities.add(ce);

    // Route measurements
    measureRoute();
  };

  const routeMeasurementEntities: Entity[] = [];
  const measureRoute = () => {
    for (const e of routeMeasurementEntities) {
      viewer.entities.remove(e);
    }
    for (let i = 0; i < mapState.routeState.route.path.length - 1; i += 1) {
      const p1 = mapState.routeState.route.path[i];
      const p2 = mapState.routeState.route.path[i + 1];

      const dist = distance(
        point([p1.longitude, p1.latitude]),
        point([p2.longitude, p2.latitude])
      );
      const center = centerOfMass(
        lineString([
          [p1.longitude, p1.latitude],
          [p2.longitude, p2.latitude],
        ])
      );

      const e = createAircraftEntity({
        label: `${dist.toFixed(2)} mi`,
        longitude: center.geometry.coordinates[0],
        latitude: center.geometry.coordinates[1],
        altitude: 100,
        color: Color.TRANSPARENT,
      });
      viewer.entities.add(e);
      routeMeasurementEntities.push(e);
    }
  };

  viewer.selectedEntityChanged.addEventListener(onEntitySelected);

  const screenEntities = {
    airports: new CustomDataSource('screenEntities.airports'),
    stations: new CustomDataSource('screenEntities.stations'),
    cameraSites: new CustomDataSource('screenEntities.cameraSites'),
    mwos: new CustomDataSource('screenEntities.mwos'),
  };
  viewer.dataSources.add(screenEntities.airports);
  viewer.dataSources.add(screenEntities.stations);
  viewer.dataSources.add(screenEntities.cameraSites);
  viewer.dataSources.add(screenEntities.mwos);

  screenEntities.airports.show = mapSettings.showMarkers.airports;
  screenEntities.stations.show = mapSettings.showMarkers.weatherStations;
  screenEntities.cameraSites.show = mapSettings.showMarkers.cameraSites;

  const fetchScreenData = async () => {
    const bounds = viewer.camera.computeViewRectangle(Ellipsoid.WGS84);
    const north = bounds?.north;

    const dist = Math.min(
      2e5,
      distance(
        point([bounds?.east * 57.295, bounds?.north * 57.295]),
        point([bounds?.west * 57.295, bounds?.south * 57.295]),
        { units: 'meters' }
      )
    );

    const cartesian = viewer.camera.pickEllipsoid(
      new Cartesian2(
        viewer.canvas.clientWidth / 2,
        viewer.canvas.clientHeight / 2
      ),
      Ellipsoid.WGS84
    ) as Cartesian3;

    if (!cartesian) {
      return;
    }

    const pos = Cartographic.fromCartesian(cartesian, Ellipsoid.WGS84);

    let sites: Station[] | SiteDetails[] = [];
    if (mapState.mapSettings.showMarkers.cameraSites) {
      sites = await getScreenSites(pos, dist);
      screenEntities.cameraSites.entities.removeAll();
      for (const s of sites) {
        screenEntities.cameraSites.entities.add(createStationEntity(s));
      }
      const mwos = await getScreenMwos(pos, dist);
      screenEntities.mwos.entities.removeAll();
      for (const m of mwos) {
        screenEntities.mwos.entities.add(createMwosEntity(m));
      }
    }
    if (mapState.mapSettings.showMarkers.weatherStations) {
      const stations = await getScreenStations(pos, dist);

      screenEntities.stations.entities.removeAll();
      // Skip stations we're already showing via camera sites
      for (const s of stations) {
        if (sites?.find((site) => site.stationId === s.id)) {
          continue;
        }
        if (
          s.flightCategory === 'VFR' &&
          mapSettings.showMarkers.vfr === false
        ) {
          continue;
        }
        if (
          s.flightCategory === 'MVFR' &&
          mapSettings.showMarkers.mvfr === false
        ) {
          continue;
        }
        if (
          s.flightCategory === 'LIFR' &&
          mapSettings.showMarkers.lifr === false
        ) {
          continue;
        }
        if (
          s.flightCategory === 'IFR' &&
          mapSettings.showMarkers.ifr === false
        ) {
          continue;
        }
        if (
          s.flightCategory === undefined &&
          mapSettings.showMarkers.noFlightCategory === false
        ) {
          continue;
        }

        screenEntities.stations.entities.add(createStationEntity(s));
      }
    }
    if (mapState.mapSettings.showMarkers.airports) {
      const airports = await getScreenAirports(pos, dist);
      screenEntities.airports.entities.removeAll();
      for (const a of airports) {
        screenEntities.airports.entities.add(createAirportEntity(a));
      }
    }
    viewer.scene.requestRender();
  };
  const removeCameraMoveEndListener =
    viewer.camera.moveEnd.addEventListener(fetchScreenData);

  const cameraMoveInterval = setInterval(
    () => setHeading(viewer.camera.heading),
    2e4
  );

  viewer.camera.percentageChanged = 0.01;
  const removeCameraChangedListener = viewer.camera.changed.addEventListener(
    () => setHeading(viewer.camera.heading)
  );

  const fetchRouteData = async () => {
    if (mapSettings.showMarkers.airports) {
      const airportsAndEntities = await refreshAndDrawRouteAirports({
        viewer,
        route: mapState.routeState.route,
        airportEntities: mapState.airportEntities,
      });
      mapState.airports = airportsAndEntities.airports;
      mapState.airportEntities = airportsAndEntities.entities;
    }

    if (mapSettings.showMarkers.weatherStations) {
      const stationsAndEntities = await refreshAndDrawRouteStations({
        viewer,
        route: mapState.routeState.route,
        stationEntities: mapState.stationEntities,
      });
      mapState.stations = stationsAndEntities.stations;
      mapState.stationEntities = stationsAndEntities.entities;
    }

    mapState.mwosEntities = await refreshAndDrawRouteMwos({
      viewer,
      route: mapState.routeState.route,
      mwosEntities: mapState.mwosEntities,
    });
  };

  return {
    dispose: () => {
      removeCameraMoveEndListener();
      removeCameraChangedListener();
      clearInterval(cameraMoveInterval);
      viewer.destroy();
    },
    clearSelectedEntity: () => {
      viewer.selectedEntity = undefined;
    },
    setAircraft: (aircraft) => {
      if (!aircraft) {
        return;
      }
      if (!viewer.dataSourceDisplay) {
        return;
      }
      for (const a of mapState.aircraftEntities) {
        viewer.entities.remove(a);
      }
      mapState.aircraft = [];
      mapState.aircraftEntities = [];
      // aircraftEntities.removeAll();
      const valid = aircraft.filter(
        (a) => a.alt_baro === 'ground' || a.alt_baro !== undefined
      );
      for (const a of valid) {
        const height = a.alt_baro === 'ground' ? 0 : a.alt_baro;
        // const height = a.alt_baro === 'ground' ? 0 : a.alt_baro;
        const e = createAircraftEntity({
          label: a.flight,
          latitude: a.lat,
          longitude: a.lon,
          altitude: height,
          color: Color.RED,
        });
        mapState.aircraftEntities.push(e);
        viewer.entities.add(e);
      }
    },
    viewer,
    setCorridor: (r: Route) => {
      mapState.undoActions.push({
        type: MapUndoActionType.ChangeCorridorWidth,
        prev: mapState.routeState.route.corridor,
        next: r.corridor,
      });
      mapState.routeState.route.corridor = r.corridor;
      setUndoActions(mapState.undoActions);
      redrawRouteEntities();
    },
    undo: () => {
      mapState.undoActions = executeUndoAction(
        mapState.undoActions,
        mapState.routeState.route,
        (newRoute: Route) => {
          mapState.routeState.route = newRoute;
          redrawRouteEntities();
          fetchRouteData();
        }
      );
      if (mapState.undoActions.length === 0) {
        setUndoActions([]);
      } else {
        setUndoActions(mapState.undoActions);
      }
      setRouteState(mapState.routeState);
    },
    flyTo: (position: LatLng) => {
      const { latitude, longitude } = position;
      const camPos = screenSpaceCoordToDeg(
        viewer,
        new Cartesian3(
          viewer.canvas.clientWidth / 2,
          viewer.canvas.clientHeight / 2
        )
      );
      const dist = distance(
        point([camPos?.longitude, camPos.latitude]),
        point([longitude, latitude]),
        { units: 'miles' }
      );
      let duration = 0;
      if (dist < 100) {
        duration = 1;
      }
      viewer.camera.flyTo({
        destination: Cartesian3.fromDegrees(longitude, latitude, 2e4),
        duration,
      });
    },
    measure: () => {
      measureRoute();
    },
    setRoute: (r) => {
      mapState.routeState.route.corridor = r.corridor;
      mapState.routeState.route.path = r.path.map((point) => ({
        latitude: point.latitude,
        longitude: point.longitude,
        altitude: 100,
      }));
      mapState.undoActions = [];
      setUndoActions([]);
      redrawRouteEntities();
      fetchRouteData();
    },
    setLocked: (l) => {
      mapState.routeState.locked = l;
      redrawRouteEntities();
    },
    setMapSettings: (mapSettings: MapSettings) => {
      mapState.mapSettings = mapSettings;

      screenEntities.airports.show = mapSettings.showMarkers.airports;
      screenEntities.stations.show = mapSettings.showMarkers.weatherStations;
      screenEntities.cameraSites.show = mapSettings.showMarkers.cameraSites;
      screenEntities.mwos.show = mapSettings.showMarkers.cameraSites;

      if (!mapSettings.showMarkers.airports) {
        screenEntities.airports.entities.removeAll();
      }

      if (!mapSettings.showMarkers.weatherStations) {
        screenEntities.stations.entities.removeAll();
      } else {
        const { vfr, mvfr, lifr, ifr, noFlightCategory } =
          mapSettings.showMarkers;
        const es = screenEntities.stations.entities;
        if (!vfr) {
          entitiesWithFlightCategory(es, 'VFR').map((e) => es.removeById(e.id));
        }
        if (!mvfr) {
          entitiesWithFlightCategory(es, 'MVFR').map((e) =>
            es.removeById(e.id)
          );
        }
        if (!lifr) {
          entitiesWithFlightCategory(es, 'LIFR').map((e) =>
            es.removeById(e.id)
          );
        }
        if (!ifr) {
          entitiesWithFlightCategory(es, 'IFR').map((e) => es.removeById(e.id));
        }
        if (!noFlightCategory) {
          entitiesWithFlightCategory(es).map((e) => es.removeById(e.id));
        }
      }

      if (!mapSettings.showMarkers.cameraSites) {
        screenEntities.cameraSites.entities.removeAll();
        screenEntities.mwos.entities.removeAll();
      }

      fetchScreenData();
    },
    setHeading: (headingDeg: number) => {
      viewer.camera.flyTo({
        destination: viewer.camera.positionWC,
        orientation: {
          heading: headingDeg,
          pitch: viewer.camera.pitch,
          roll: viewer.camera.roll,
        },
        duration: 0.5,
      });
    },
    setRouteState: (s: RouteState) => {
      mapState.routeState = { ...s };
      // mapState.routeState.route.path = s.path;
      // mapState.route.corridor = s.corridorWidthMi;
      redrawRouteEntities();

      if (mapState.routeState.route.path.length === 0) {
        mapState.undoActions = [];
        setUndoActions([]);
        measureRoute();
      }
    },
  };
};

const entitiesWithFlightCategory = (
  entities: EntityCollection,
  flightCategory?: FlightCategory
) => {
  if (flightCategory === undefined) {
    return entities.values.filter(
      (e) => e.properties?.stationDetails?.getValue().metar === undefined
    );
  }
  return entities.values.filter(
    (e) =>
      e.properties?.stationDetails.getValue().metar?.flightCategory ===
      flightCategory
  );
};
