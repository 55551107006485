import { css } from '@emotion/css';
import { useCallback, useEffect, useState } from 'react';
import { LegacyRoute, getRoutes } from '../../../api/legacy';
import routesIcon from '../../../assets/images/route_light.svg';
import { colors } from '../../../constants/colors';
import useStore from '../../../state/state';
import Button from '../../Button';
import Drawer from '../../Drawer';
import IconAddOutline from '../../svg-icons/AddOutline';

type RoutesListProps = {
  routes: LegacyRoute[];
  onSelect: (r: LegacyRoute) => void;
};

const RoutesList = ({ routes, onSelect }: RoutesListProps) => {
  return (
    <ul>
      {routes.map((r) => (
        <li
          key={r.Id}
          onClick={() => {
            onSelect(r);
          }}
        >
          {r.Name}
        </li>
      ))}
    </ul>
  );
};

type RoutesDrawerProps = {
  isOpen: boolean;
  onClose: () => void;
  onSelect: (r: LegacyRoute) => void;
};

export default function RoutesDrawer({
  isOpen,
  onClose,
  onSelect,
}: RoutesDrawerProps) {
  const userSavedRoutes = useStore((store) => store.userSavedRoutes);
  const setUserSavedRoutes = useStore((store) => store.setUserSavedRoutes);
  const routeState = useStore((store) => store.routeState);
  const setRouteState = useStore((store) => store.setRouteState);

  const [routes, setRoutes] = useState<LegacyRoute[]>([]);

  const loadRoutes = useCallback(async () => {
    const legacyRoutes = await getRoutes();

    // Get routes from new API and database
    // const routes = await getUserRoutes();
    // const routes = await getUserRoutes();
    // console.log(routes);
    // const mappedRoutes: LegacyRoute[] = [];
    // if (routes?.length > 0) {
    //   for (const r of routes) {
    //     const path = JSON.parse(r.path);
    //     mappedRoutes.push({
    //       Id: r.id,
    //       Name: r.routeName,
    //       Corridor: r.corridorWidthMeters,
    //       Path: path.coordinates.map((p) => ({
    //         Latitude: p[1],
    //         Longitude: p[0],
    //       })),
    //     });
    //   }
    // }
    const mappedRoutes: LegacyRoute[] = [];

    const allRoutes = [...legacyRoutes.results, ...mappedRoutes];
    if (allRoutes.length > 0) {
      setUserSavedRoutes(allRoutes);
      setRoutes(allRoutes);
    }
  }, []);

  useEffect(() => {
    if (userSavedRoutes.length === 0) {
      loadRoutes();
    }
  }, [userSavedRoutes]);

  const onClickNewRoute = useCallback(() => {
    setRouteState({
      route: {
        path: [],
        corridor: 35,
      },
      editing: true,
      name: 'New Route',
      locked: false,
      isNewRoute: true,
    });
  }, [routeState]);

  const onSelectRoute = useCallback(
    (r: LegacyRoute) => {
      setRouteState({
        ...routeState,
        name: r.Name,
        editing: true,
        locked: true,
        isNewRoute: false,
      });
      onSelect(r);
    },
    [routeState, onSelect]
  );

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      icon={<img src={routesIcon} style={{ width: '1em' }} />}
      title="Routes"
      screenSide="left"
      className={style}
    >
      <div className="controls">
        <Button
          primary
          icon={<IconAddOutline width="1.25em" height="1.25em" />}
          text={'New route'}
          onClick={onClickNewRoute}
        />
      </div>
      {routes.length > 0 ? (
        <RoutesList routes={routes} onSelect={onSelectRoute} />
      ) : (
        <span>Loading routes...</span>
      )}
    </Drawer>
  );
}

const style = css({
  '.title': {
    fontSize: '1.5em',
    fontWeight: 600,
  },
  '.controls': {
    borderBottom: '1px solid #ddd',
    paddingBottom: '1em',
    display: 'flex',
    flexDirection: 'row',
  },
  ul: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
    marginTop: 10,
    li: {
      fontWeight: 400,
      margin: 0,
      fontSize: '1.15em',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      cursor: 'pointer',
      padding: '.25em .5em',
      borderRadius: 3,
      ':hover': {
        backgroundColor: '#0000000a',
        color: colors.primaryLight,
      },
    },
  },
});
