import { create } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';
import { LegacyRoute } from '../api/legacy';
import { UserStation } from '../api/types';
import { Aircraft, MapSettings, MapUndoAction, Route } from '../types/types';
import { defaultMapSettings, defaultRouteState } from './defaults';

export type RouteState = {
  editing: boolean;
  locked: boolean;
  name?: string;
  isNewRoute: boolean;
  route: Route;
};

type Store = {
  aircraft: Aircraft[];
  setAircraft: (a: Aircraft[]) => void;
  mapSettings: MapSettings;
  setMapSettings: (s: MapSettings) => void;
  mapUndoActions: MapUndoAction[];
  setMapUndoActions: (a: MapUndoAction[]) => void;
  heading: number;
  setHeading: (h: number) => void;
  userSavedStations: UserStation[];
  setUserSavedStations: (s: UserStation[]) => void;
  userSavedRoutes: LegacyRoute[];
  setUserSavedRoutes: (r: LegacyRoute[]) => void;

  routeState: RouteState;
  setRouteState: (s: RouteState) => void;

  statusMessage?: string;
  setStatusMessage: (s?: string) => void;
};

const useStore = create<Store>()(
  subscribeWithSelector((set) => ({
    aircraft: [],
    setAircraft: (aircraft) => set({ aircraft }),

    mapSettings: defaultMapSettings,
    setMapSettings: (mapSettings) => set({ mapSettings }),

    mapUndoActions: [],
    setMapUndoActions: (a: MapUndoAction[]) => set({ mapUndoActions: a }),

    heading: 0,
    setHeading: (heading) => set({ heading }),

    userSavedStations: [],
    setUserSavedStations: (s) => set({ userSavedStations: s }),

    userSavedRoutes: [],
    setUserSavedRoutes: (r) => set({ userSavedRoutes: r }),

    routeState: defaultRouteState,
    setRouteState: (s) => set({ routeState: s }),

    statusMessage: undefined,
    setStatusMessage: (s) => set({ statusMessage: s }),
  }))
);

export default useStore;
