// icon:plane-tilt | Tabler Icons https://tablericons.com/ | Csaba Kissi
function IconPlaneTilt(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      viewBox="0 0 24 24"
      height="1em"
      width="1em"
      {...props}
    >
      <path stroke="none" d="M0 0h24v24H0z" />
      <path d="M14.5 6.5l3-2.9a2.05 2.05 0 012.9 2.9l-2.9 3L20 17l-2.5 2.55L14 13l-3 3v3l-2 2-1.5-4.5L3 15l2-2h3l3-3-6.5-3.5L7 4l7.5 2.5z" />
    </svg>
  );
}

export default IconPlaneTilt;
