import { css } from '@emotion/css';

import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  deleteUserStation,
  getUserStations,
  memoGetSiteDetails,
  memoGetStationDetails,
  saveUserStation,
} from '../../api';
import { SiteDetails, Station, StationDetails } from '../../api/types';
import { colors } from '../../constants/colors';
import useStore from '../../state/state';
import { StationModalTabName } from '../../types/types';
import IconClose from '../svg-icons/Close';
import IconStar from '../svg-icons/Star';
import IconStarOutline from '../svg-icons/StarOutline';
import OverviewTab from './station/OverviewTab';
import PanoramaTab from './station/PanoramaTab';
import Tabs from './station/Tabs';
import WeatherTab from './station/WeatherTab';

type Props = {
  isVisible: boolean;
  close: () => void;
  station: Station;
};

export default function StationModal({ isVisible, close, station }: Props) {
  const [saved, setSaved] = useState<boolean>(false);
  const setUserSavedStations = useStore((store) => store.setUserSavedStations);
  const userSavedStations = useStore((store) => store.userSavedStations);
  const [currentTab, setCurrentTab] = useState<StationModalTabName>();
  const [stationDetails, setStationDetails] = useState<
    StationDetails | SiteDetails
  >();

  useEffect(() => {
    if (!station) {
      return;
    }
    if (station.id !== stationDetails?.id) {
      setStationDetails(undefined);
    }
    if (!stationDetails) {
      (async () => {
        console.log('fetching fcs');
        console.log(station);
        if (station.stationType === 'weather_station') {
          const details = await memoGetStationDetails(station.id);
          setStationDetails(details);
        } else if (station.stationType === 'faa_camera_site') {
          const details = await memoGetSiteDetails(station.id);
          if (details.stationId) {
            const stDetails = await memoGetStationDetails(details.stationId);
            details.metar = stDetails.metar;
          }
          setStationDetails(details);
        } else if (station.stationType === 'mwos') {
          setStationDetails(station);
        }
      })();
      return;
    }
    setSaved(
      userSavedStations.find((s) => s.stationId === station.id) !== undefined
    );
    setCurrentTab(stationDetails.cameras ? 'overview' : 'weather');
  }, [station, stationDetails]);

  const cameras = useMemo(() => {
    let cameras;
    if (stationDetails?.cameras) {
      cameras = [
        ...stationDetails.cameras.filter((c) => c.currentImageUrl !== ''),
      ];
      cameras.sort((a, b) => a.displayOrder - b.displayOrder);
    }
    return cameras;
  }, [stationDetails]);

  const isMwos = stationDetails?.observations?.length > 0;

  const title = useMemo(() => {
    if (!stationDetails) {
      return '';
    }
    if (stationDetails.icao) {
      return `${stationDetails.siteName} (${stationDetails.icao})`;
    }
    if (stationDetails.icaoId) {
      return `${stationDetails.siteName} (${stationDetails.icaoId})`;
    }
    if (stationDetails.siteIdentifier) {
      return `${stationDetails.siteName} (${stationDetails.siteIdentifier})`;
    }
    return stationDetails.siteName;
  }, [stationDetails]);

  const onTabClick = (tabName: StationModalTabName) => setCurrentTab(tabName);

  const save = useCallback(async () => {
    const stationType = isMwos
      ? 'mwos'
      : stationDetails.cameras?.length > 0
      ? 'faa_camera_site'
      : 'weather_station';
    let dynamodbId;
    if (stationType === 'weather_station' || stationType === 'mwos') {
      dynamodbId = `ICAO_${stationDetails.icaoId}`;
    } else {
      dynamodbId = `ICAO_${stationDetails.icao}`;
    }
    try {
      await saveUserStation(stationDetails.id, dynamodbId, stationType);
      setSaved(true);
      const s = await getUserStations();
      s.sort((a, b) => a.displayOrder - b.displayOrder);
      setUserSavedStations(s);
    } catch (err) {
      console.error('error saving user station', err);
    }
  }, [stationDetails]);

  const unSave = useCallback(async () => {
    const userStation = userSavedStations.find(
      (s) => s.stationId === stationDetails.id
    );
    if (!userStation) {
      return;
    }
    try {
      await deleteUserStation(userStation.id);
      setSaved(false);
      const s = await getUserStations();
      s.sort((a, b) => a.displayOrder - b.displayOrder);
      setUserSavedStations(s);
    } catch (err) {
      console.error('error deleting user station', err);
    }
  }, [stationDetails]);

  if (!isVisible || !stationDetails) {
    return false;
  }

  const isSaveable = stationDetails.icaoId || stationDetails.icao;

  return (
    // <ErrorBoundary fallback={<></>}>
    <div className={styles.modalContainer}>
      <div className={styles.modalBackdrop} />
      <div className={styles.modal}>
        <div className={styles.header}>
          <div className={styles.title}>
            {isSaveable && (
              <>
                {saved ? (
                  <IconStar className="save-button" onClick={unSave} />
                ) : (
                  <IconStarOutline className="save-button" onClick={save} />
                )}
              </>
            )}
            {title}
          </div>
          <div className="controls">
            <IconClose
              className="close-button"
              onClick={() => {
                close();
                setStationDetails(undefined);
              }}
            />
          </div>
        </div>
        <Tabs
          currentTab={currentTab}
          onClick={onTabClick}
          isMwos={isMwos}
          hasWeather={
            stationDetails.metar || stationDetails.observations?.length > 0
          }
        />
        {currentTab === 'overview' && (
          <OverviewTab
            station={stationDetails}
            cameras={cameras}
            isMwos={isMwos}
          />
        )}
        {currentTab === 'weather' && (
          <WeatherTab station={stationDetails} isMwos={isMwos} />
        )}
        {currentTab === 'panorama' && <PanoramaTab station={stationDetails} />}
      </div>
    </div>
    // </ErrorBoundary>
  );
}

const styles = {
  modalContainer: css({
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    alignContent: 'center',
    pointerEvents: 'none',
  }),
  modalBackdrop: css({
    pointerEvents: 'none',
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 1,
  }),
  modal: css({
    pointerEvents: 'all',
    borderRadius: 5,
    width: 'min(70vh, 100vw)',
    minWidth: '25%',
    aspectRatio: 1.5,
    zIndex: 2,
    animation: 'slideUp .5s',
    '@keyframes slideUp': {
      '0%': {
        opacity: 0,
        transform: 'translate(0,50px)',
      },
      '100%': {
        opacity: 1,
        transform: 'translate(0,0)',
      },
    },
    '.image-wrapper': {
      display: 'block',
      img: {
        width: '100%',
      },
    },
    '.sub': {
      textAlign: 'center',
      width: 'auto',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      backgroundColor: '#afe9af',
      fontSize: '1em',
      '@media (max-width: 600px)': {
        fontSize: '.75em',
      },
    },
  }),
  header: css({
    '.save-button': {
      width: '1em',
      height: '1em',
      marginRight: '.5em',
      color: colors.primaryLight,
      transform: 'translateY(.1em)',
      cursor: 'pointer',
      ':hover::after': {
        display: 'block',
        content: '"Save station"',
        position: 'absolute',
      },
    },
    backgroundColor: '#ffffffdd',
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    position: 'relative',
    padding: '.75em',
    '.controls': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: '.75em',
      position: 'absolute',
      top: 0,
      right: 0,
      '.close-button': {
        width: '2em',
        height: '2em',
        cursor: 'pointer',
        ':hover': {
          opacity: 0.65,
        },
      },
    },
  }),
  title: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: '2em',
    color: '#222',
    fontWeight: 'bold',
    '@media (max-width: 600px)': {
      fontSize: '1em',
    },
  }),
};
