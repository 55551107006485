import {
  axisX,
  axisY,
  dot,
  formatIsoDate,
  lineY,
  plot,
  pointerX,
  ruleX,
  ruleY,
  text,
  windowY,
} from '@observablehq/plot';
import { useEffect, useRef, useState } from 'react';
import { HHmmz } from './datetimes';

type Props = {
  data: {
    time: Date;
    value: number;
  }[];
  height?: number;
};

export default function Temperature({ data, height }: Props) {
  const [_data, setData] = useState(data);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const fetchData = async () => {
    const res = await fetch('http://192.168.1.157:12345/mwos/1');
    const json = await res.json();
    setData(
      json.observations.map((o) => {
        return {
          time: new Date(o.observationTime),
          value: o.tempC,
        };
      })
    );
  };

  useEffect(() => {
    if (_data === undefined) {
      fetchData();
      return;
    }
    if (!containerRef.current) {
      return;
    }

    const p = plot({
      y: { grid: true, nice: true },
      // color: { scheme: "burd" },
      height: height || 200,
      marginLeft: 50,
      color: {
        // range: ['#007', '#707'],
        // interpolate: 'hsl',
        scheme: 'Blues',
      },
      marks: [
        ruleY([0]),
        ruleX(
          _data,
          pointerX({
            x: 'time',
            py: 'value',
            stroke: '#444',
            strokeWidth: 1,
            strokeDasharray: '2,4',
          })
        ),
        dot(_data, { x: 'time', y: 'value', stroke: 'value', r: 2.5 }),
        lineY(
          _data,
          windowY(1, {
            x: 'time',
            y: 'value',
            curve: 'catmull-rom',
            stroke: '#444',
            strokeWidth: 1,
          })
        ),
        text(
          _data,
          pointerX({
            px: 'time',
            py: 'value',
            frameAnchor: 'top',
            dy: -17,
            text: (d) =>
              [
                `Time: ${formatIsoDate(d.time)}`,
                `Temperature ${d.value.toFixed(1)} °C`,
              ].join('  '),
          })
        ),
        axisY({
          anchor: 'left',
          labelAnchor: 'center',
          label: 'Temperature °C',
          labelOffset: 48,
          dx: -2,
          tickFormat: (d) => `${d.toFixed(0)} °C`,
        }),
        axisX({
          anchor: 'bottom',
          tickFormat: (d) => `${HHmmz(d)}`,
        }),
      ],
    });
    containerRef.current.append(p);
    return () => p.remove();
  }, [_data]);

  if (_data === undefined) {
    return false;
  }

  return <div ref={containerRef} />;
}
