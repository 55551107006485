import { css } from '@emotion/css';
import { useCallback, useState } from 'react';
import { Camera, Station } from '../../../api/types';
import AllImages from './AllImages';
import CameraTabs from './CameraTabs';
import SingleImage from './SingleImage';
import WeatherString from './WeatherString';

type OverviewTabProps = {
  station: Station[];
  cameras: Camera[];
  isMwos: boolean;
};

export default function OverviewTab({
  station,
  cameras,
  isMwos,
}: OverviewTabProps) {
  const [currentCamera, setCurrentCamera] = useState<Camera | undefined>();
  const onClickImage = useCallback((camera: Camera) => {
    setCurrentCamera(camera);
  }, []);
  const onClickTab = useCallback((camera?: Camera) => {
    setCurrentCamera(camera);
  }, []);

  return (
    <>
      <WeatherString station={station} />
      {cameras && (
        <>
          <CameraTabs
            cameras={cameras}
            currentCamera={currentCamera}
            isMwos={isMwos}
            onClickTab={onClickTab}
          />
          <div className={styles}>
            {currentCamera ? (
              <SingleImage camera={currentCamera} isMwos={isMwos} />
            ) : (
              <AllImages
                cameras={cameras}
                isMwos={isMwos}
                onClick={onClickImage}
              />
            )}
          </div>
        </>
      )}
    </>
  );
}

const styles = css({
  display: 'block',
  position: 'relative',
  overflow: 'hidden',
});
