import { css } from '@emotion/css';
// import Station from '../../svg-icons/Station';
import StationsIcon from '../../../assets/images/station_light.svg';

type Props = {
  onClick: () => void;
};

export default function StationsButton({ onClick }: Props) {
  return (
    <div className={styles} onClick={onClick}>
      <img src={StationsIcon} width={32} height={32} />
      <label>Stations</label>
    </div>
  );
}

const styles = css({
  backgroundColor: '#ffffffdd',
  width: '65px',
  height: '65px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 3,
  color: '#222',
  paddingTop: 2,
  label: {
    fontSize: 14,
  },
  ':hover': {
    opacity: 0.85,
    cursor: 'pointer',
  },
  '@media (max-width: 600px)': {
    width: 50,
    height: 50,
    label: {
      fontSize: 11,
    },
  },
});
