import { css } from '@emotion/css';
import { Camera, MwosCamera } from '../../../api/types';
import { minutesAgo } from './functions';

type Props = {
  cameras: Camera[] | MwosCamera[];
  isMwos: boolean;
  onClick: (c: Camera | MwosCamera) => void;
};

export default function AllImages({ cameras, isMwos, onClick }: Props) {
  return (
    <div className={styles}>
      {cameras &&
        cameras.map((camera, i) => (
          <div key={i} className="image-wrapper">
            <img src={camera.currentImageUrl} onClick={() => onClick(camera)} />
            <span className="sub">
              {isMwos ? camera.directionText : camera.cameraDirection} (
              {minutesAgo(
                isMwos
                  ? camera.currentImageObservationTime
                  : camera.cameraLastSuccess
              )}{' '}
              min ago)
            </span>
          </div>
        ))}
    </div>
  );
}

const styles = css({
  backgroundColor: '#ffffffdd',
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  columnGap: 2,
  rowGap: 2,
  padding: 2,
  img: {
    cursor: 'pointer',
  },
  '.images-wrapper': {
    display: 'block',
    position: 'relative',
    overflow: 'hidden',
  },
});
