import { css } from '@emotion/css';
import IconLock from '../../svg-icons/Locked';
import IconUnlock from '../../svg-icons/Unlocked';

type Props = {
  isLocked: boolean;
  onClick: () => void;
};

export default function LockButton({ isLocked, onClick }: Props) {
  const Icon = isLocked ? (
    <IconLock width="35px" height="35px" />
  ) : (
    <IconUnlock width="35px" height="35px" />
  );
  return (
    <div className={styles} onClick={onClick}>
      {Icon}
      <label>{isLocked ? 'Unlock' : 'Lock'}</label>
    </div>
  );
}

const styles = css({
  backgroundColor: '#ffffffdd',
  width: '65px',
  height: '65px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 3,
  color: '#222',
  paddingTop: 5,
  label: {
    fontSize: 14,
  },
  ':hover': {
    opacity: 0.85,
    cursor: 'pointer',
  },
  '@media (max-width: 600px)': {
    width: 50,
    height: 50,
    label: {
      fontSize: 11,
    },
  },
});
