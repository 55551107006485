import { default as Row } from './DecodedWeatherRow';
import { displayDatetime, minutesAgo } from './functions';

type DecodedWeatherProps = {
  observation: {
    flightCategory: string;
    dewpointC: number;
    humidityPct: number;
    observationTime: string;
    precipIn: number;
    precipType: string;
    pressureHpa: number;
    rawText: string;
    tempC: number;
    wetbulbTempC: number;
    windDirDegrees: string;
    windGustKt: number;
    windSpeedKt: number;
    windsText: string;
  };
};

export default function DecodedWeather({ observation }: DecodedWeatherProps) {
  const c2f = (c) => (c * (9.0 / 5.0) + 32).toFixed(1);
  const fmtC = (deg) => `${parseFloat(deg).toFixed(2)} °C (${c2f(deg)} °F)`;

  if (!observation) {
    return false;
  }

  const clouds = observation.skyCondition;
  if (clouds) {
    clouds.sort((a, b) => a.cloudBaseFtAgl - b.cloudBaseFtAgl);
  }
  // console.log(ceiling);

  return (
    <div
      style={{
        backgroundColor: '#ffffffdd',
        paddingBottom: 20,
      }}
    >
      <Row
        label="Observation Time"
        value={`${displayDatetime(observation.observationTime)} (${minutesAgo(
          observation.observationTime
        )} min ago)`}
      />
      <Row label="Flight Category" value={observation.flightCategory} />
      <Row label="Metar Type" value={observation.metarType} />
      <Row label="Temperature" value={fmtC(observation.tempC)} />
      <Row label="Dew Point" value={fmtC(observation.dewPointC)} />
      {observation.altimInHg && (
        <Row
          label="Pressure (altimeter)"
          value={`${observation.altimInHg} inHg`}
        />
      )}
      <Row
        label="Winds"
        value={`From ${observation.windDirDegrees}° at ${observation.windSpeedKt} knots`}
      />
      <Row label="Visibility" value={`${observation.visibilityStatuteMi} mi`} />
      <Row label="Weather" value={observation.wxString} />
      {clouds && (
        <Row
          label="Clouds"
          value={clouds.map((c, i) => (
            <>
              <span key={i}>
                {c.skyCover} at {c.cloudBaseFtAgl} ft AGL
              </span>
              <br />
            </>
          ))}
        />
      )}
      {/* <Row
        label="Wet Bulb Temperature"
        value={fmtC(observation.wetbulbTempC)}
      />
      <Row label="Precipitation Rate" value={observation.precipIn} /> */}
    </div>
  );
}
