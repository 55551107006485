import { LatLng } from '@/types/types';
import buffer from '@turf/buffer';
import turfDistance from '@turf/distance';
import greatCircle from '@turf/great-circle';
import { Feature, LineString, Polygon, Position } from '@turf/helpers';
import memo from 'memoize/dist/index';

const memoDistance = memo(turfDistance, {
  maxAge: 180e3,
  cacheKey: JSON.stringify,
});
const memoGreatCircle = memo(greatCircle, {
  maxAge: 180e3,
  cacheKey: JSON.stringify,
});

export const calcBuffer = memo(
  (path: LatLng[], distance: number): Feature<Polygon> => {
    if (path.length === 1) {
      path = [
        path[0],
        {
          latitude: path[0].latitude + Math.random() * 0.0001,
          longitude: path[0].longitude + Math.random() * 0.0001,
        },
      ];
    }
    const corridorCoords = path.map((p) => [p.longitude, p.latitude]);

    const greatCircleCoords: Position[] = [];
    let lastPoint = corridorCoords[0];
    corridorCoords.slice(1).forEach((pt) => {
      const d = memoDistance(lastPoint, pt, { units: 'degrees' });
      greatCircleCoords.push(
        ...(memoGreatCircle(lastPoint, pt, {
          npoints: Math.max(1, Math.ceil(d)),
        }).geometry.coordinates as Position[])
      );
      lastPoint = pt;
    });

    const feature: Feature<LineString> = {
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'LineString',
        coordinates: greatCircleCoords,
      },
    };
    const corridorFeature = buffer(feature, distance, { units: 'miles' });

    return corridorFeature;
  },
  { maxAge: 180e3, cacheKey: JSON.stringify }
);
