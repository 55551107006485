// icon:helicopter-symbol | Fontawesome https://fontawesome.com/ | Fontawesome
function IconHelicopterSymbol(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 512 512"
      fill="currentColor"
      height="1em"
      width="1em"
      {...props}
    >
      <path d="M445.3 224H510C495.6 108.2 403.8 16.4 288 2v64.7c80.4 13.4 143.9 76.9 157.3 157.3zm64.7 64h-64.7c-13.4 80.4-76.9 143.9-157.3 157.4V510c115.8-14.4 207.6-106.2 222-222zM2 288c14.4 115.8 106.2 207.6 222 222v-64.6c-80.4-13.5-143.9-77-157.3-157.4H2zm0-64h64.7C80.1 143.6 143.6 80.1 224 66.7V2C108.2 16.4 16.4 108.2 2 224zm206-64c0-17.7-14.3-32-32-32s-32 14.3-32 32v192c0 17.7 14.3 32 32 32s32-14.3 32-32v-64h96v64c0 17.7 14.3 32 32 32s32-14.3 32-32V160c0-17.7-14.3-32-32-32s-32 14.3-32 32v64h-96v-64z" />
    </svg>
  );
}

export default IconHelicopterSymbol;
