import { css } from '@emotion/css';
import { ChangeEvent, useCallback } from 'react';
import useStore from '../../../state/state';

type Props = {
  routeName: string;
};

export default function RouteInfo({ routeName }: Props) {
  const setRouteState = useStore((store) => store.setRouteState);
  const routeState = useStore((store) => store.routeState);
  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setRouteState({
        ...routeState,
        name: event.target.value,
      });
    },
    [routeState]
  );
  return (
    <div className={styles}>
      <div className="info">
        <section className="header">
          {routeState.locked && <label>{routeName}</label>}
          {!routeState.locked && (
            <div className="input">
              <input
                type="text"
                spellCheck={false}
                defaultValue={routeName}
                onChange={onChange}
              />
              <label>Route Name</label>
            </div>
          )}
        </section>
      </div>
    </div>
  );
}

const styles = css({
  position: 'absolute',
  top: 0,
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  pointerEvents: 'none',
  '.info': {
    backgroundColor: '#ffffffdd',
    borderBottomLeftRadius: 3,
    borderBottomRightRadius: 3,
    display: 'flex',
    padding: '.5em 1.5em',
    flexDirection: ' column',
    alignItems: 'center',
    '.header': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      img: {
        width: '1.5em',
        height: '1.5em',
        marginRight: '.5em',
      },
      label: {
        fontSize: '1.75em',
        textAlign: 'center',
        fontWeight: 400,
      },
      '.input': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        label: {
          paddingTop: '.25em',
          fontWeight: 400,
          fontSize: 14,
        },
      },
      input: {
        pointerEvents: 'all',
        outline: 'none',
        border: 'none',
        borderBottom: '1px solid #aaa',
        backgroundColor: 'transparent',
        fontSize: '1.75em',
        textAlign: 'center',
      },
    },
    '@keyframes slideUp': {
      '0%': {
        opacity: 0,
        transform: 'translate(0,50px)',
      },
      '100%': {
        opacity: 1,
        transform: 'translate(0,0)',
      },
    },
  },
});
