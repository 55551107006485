import { css } from '@emotion/css';
import { useCallback } from 'react';
import { colors } from '../constants/colors';

type Props = {
  value: boolean;
  onChange: (b: boolean) => void;
};

export default function ToggleSwitch({ value, onChange }: Props) {
  const _onChange = useCallback(() => onChange(!value), [value]);
  return (
    <div className={style}>
      <label className="switch">
        <input type="checkbox" checked={value} onChange={_onChange} />
        <span className="slider round"></span>
      </label>
    </div>
  );
}

const style = css({
  '.switch': {
    position: 'relative',
    display: 'inline-block',
    width: 60,
    height: 30,
  },
  '.switch input': {
    opacity: 0,
    width: 0,
    height: 0,
  },
  '.slider': {
    position: 'absolute',
    cursor: 'pointer',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: '#ccc',
    transition: '0.25s',
  },
  '.slider:before': {
    position: 'absolute',
    content: '""',
    height: 26,
    width: 26,
    left: 2,
    bottom: 2,
    backgroundColor: 'white',
    transition: '0.25s',
  },
  'input:checked + .slider': {
    backgroundColor: colors.primaryLight,
  },
  'input:checked + .slider:before': {
    transform: 'translateX(30px)',
  },
  '.slider.round': {
    borderRadius: '35px',
  },
  '.slider.round:before': {
    borderRadius: '50%',
  },
});
