import { css } from '@emotion/css';
import useStore from '../../../state/state';

export default function StatusMessage() {
  const status = useStore((store) => store.statusMessage);
  if (status === undefined) {
    return false;
  }
  return (
    <div className={styles}>
      <div className="message">{status}</div>
    </div>
  );
}

const styles = css({
  position: 'absolute',
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  bottom: 20,
  '.message': {
    backgroundColor: '#ffffffdd',
    padding: '.35em .75em',
    minWidth: 200,
    textAlign: 'center',
    borderRadius: 3,
    fontSize: '1.25em',
  },
  animation: 'slideUp .5s',
  '@keyframes slideUp': {
    '0%': {
      opacity: 0,
      transform: 'translate(0,50px)',
    },
    '100%': {
      opacity: 1,
      transform: 'translate(0,0)',
    },
  },
});
