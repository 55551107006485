export enum UserType {
  Internal,
  Public,
}

export type LatLng = {
  latitude: number;
  longitude: number;
};

export type LatLngAlt = {
  latitude: number;
  longitude: number;
  altitude?: number;
};

export type MarkerType =
  | 'cameraSites'
  | 'airports'
  | 'weatherStations'
  | 'vfr'
  | 'mvfr'
  | 'ifr'
  | 'lifr'
  | 'noFlightCategory';

export type MapType = 'default' | 'low-bandwidth';

export type MapSettings = {
  mapType: MapType;
  showMarkers: {
    [key in MarkerType]: boolean;
  };
};

export enum MapUndoActionType {
  AddRoutePoint,
  MoveRoutePoint,
  MoveRouteMidpoint,
  ChangeCorridorWidth,
}

export type MapUndoAction = {
  type: MapUndoActionType;
  prev?: any;
  next?: any;
};

export type MapMarker = {
  title?: string;
  description?: string;
  latlng: LatLng;
  extra?: {};
};

export type MapStationMarker = {
  title: string;
  description: string;
  latlng: LatLng;
  flightCategory?: FlightCategory;
  hasCameraImages: boolean;
  stationId: StationId;
};

export type MapRouteMidpointMarker = {
  latlng: LatLng;
  afterPoint: LatLng;
};

export type MapUserLocationMarker = {
  latlang: LatLng;
  gpsActive: boolean;
};

export type CameraDirection =
  | 'North'
  | 'NorthEast'
  | 'East'
  | 'SouthEast'
  | 'South'
  | 'SouthWest'
  | 'West'
  | 'NorthWest';

export type Camera = {
  id: string;
  direction: CameraDirection;
  currentImageUrl: string;
  clearDayImageUrl: string;
  observationDateTime: string;
};

export type StationId = string;

// export type Station = {
//   id: StationId;
//   name: string;
//   code: string;
//   latitude: number;
//   longitude: number;
//   metar?: {
//     rawText: string;
//     flightCategory: FlightCategory;
//   };
//   mwosWeather?: {
//     dewpointC: number;
//     humidityPct: number;
//     observationDateTime: string;
//     precipitationInchesHr: number;
//     precipitationType: string;
//     pressureHPA: number;
//     tempC: number;
//     weatherSummary: string;
//     wetBulbTempC: number;
//     windDirectionDegrees: number;
//     windGustKnots: number;
//     windSpeedKnots: number;
//     winds: string;
//   };
//   cameras: Camera[];
//   isHidden: boolean;
// };

export type Route = {
  corridor: number;
  id?: string;
  name?: string;
  numOfStations?: number;
  path: LatLngAlt[];
  stations?: null | Station[];
};

export type FlightCategory = 'VFR' | 'MVFR' | 'IFR' | 'LIFR';

export type Aircraft = {
  latitude: number;
  longitude: number;
  altitude: number;
};

export type StationModalTabName = 'overview' | 'weather' | 'panorama';
