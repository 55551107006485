import { type CognitoUserPoolConfig } from '@aws-amplify/core';
import { Authenticator } from '@aws-amplify/ui-react';
import { Amplify } from 'aws-amplify';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Index from './pages/Index';
import SignIn from './pages/SignIn';

const router = createBrowserRouter([
  {
    path: '/',
    element: <SignIn />,
  },
  {
    path: '/map',
    element: <Index />,
  },
]);

const userPoolConfig: CognitoUserPoolConfig = {
  userPoolClientId: '6af6ji7r1qndn9fp8hovhd142t',
  userPoolId: 'us-west-2_ZAnEMUXbw',
  loginWith: {
    oauth: {
      domain: 'montiscorp-prod.auth.us-west-2.amazoncognito.com',
      scopes: ['email', 'openid', 'profile', 'aws.cognito.signin.user.admin'],
      redirectSignIn: ['exp+visroute://', 'visrouteapp://'],
      redirectSignOut: ['exp+visroute://', 'visrouteapp://'],
      responseType: 'code',
      // providers: ['Google'],
    },
    email: true,
  },
};

Amplify.configure({
  Auth: {
    Cognito: userPoolConfig,
  },
});

export default function App() {
  return (
    <Authenticator.Provider>
      <RouterProvider router={router} />
    </Authenticator.Provider>
  );
}
