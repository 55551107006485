import { fetchAuthSession } from 'aws-amplify/auth';

export const get = async (route: string, accessToken = undefined) => {
  const token = accessToken ?? (await getAccessToken());
  if (!token) {
    console.error('No access token for API request', { route, accessToken });
    return { count: 0, results: [] };
  }
  try {
    const res = await fetch(
      `https://cl87ch5580.execute-api.us-west-2.amazonaws.com/${route}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return res.json();
  } catch (err) {
    console.error('error fetching routes', err);
  }
  return {};
};

export const getAccessToken = async (): Promise<string | undefined> => {
  const session = await fetchAuthSession();
  if (session) {
    const tok = session.tokens?.accessToken.toString();
    if (tok) {
      return tok;
    }
  }
  return undefined;
};

type LegacyResponse<T> = {
  count: number;
  results: T[];
};

export type LegacyRoute = {
  Id?: string;
  Name: string;
  Path: {
    Latitude: number;
    Longitude: number;
  }[];
  Corridor: number;
};

export const getRoutes = async (): Promise<LegacyResponse<LegacyRoute>> =>
  get('routes');

export const saveUserRouteLegacy = async (
  route: LegacyRoute,
  accessToken?: string
) => {
  const token = accessToken ?? (await getAccessToken());
  if (!token) {
    console.error('No access token for API request', { route, accessToken });
    return [];
  }
  const res = await fetch(
    `https://cl87ch5580.execute-api.us-west-2.amazonaws.com/routes`,
    {
      method: 'POST',
      headers: {
        Authorization: token,
      },
      body: JSON.stringify({
        Name: route.Name,
        Path: route.Path.map((p) => ({
          Latitude: p.Latitude,
          Longitude: p.Longitude,
        })),
        Corridor: route.Corridor,
      }),
    }
  );
  const json = await res.json();
  return json;
};
