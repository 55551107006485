import DecodedWeather from './DecodedWeather';
import MwosCharts from './MwosCharts';
import MwosDecodedWeather from './MwosDecodedWeather';
import WeatherString from './WeatherString';

export default function WeatherTab({ station, isMwos }) {
  return (
    <>
      <WeatherString station={station} />
      {isMwos ? (
        <MwosDecodedWeather observation={station.observations[0]} />
      ) : (
        <DecodedWeather observation={station.metar} />
      )}
      {isMwos && <MwosCharts station={station} />}
    </>
  );
}
