import memoize from 'memoize/dist';
import { LatLng } from '../types/types';
import { getAccessToken } from './legacy';
import {
  Airport,
  Mwos,
  MwosDetails,
  NewRoute,
  SearchResponse,
  Site,
  SiteDetails,
  Station,
  StationDetails,
  UserStation,
} from './types';

// type MwosDetails = {
//   siteName: string;
//   observations: Observation[];
// };

// type Observation = {
//   rawText: string;
// };

const API_TOKEN = 'VESTUG2IIGDKKCDJFDQC6ZZAODETADWB';

const API_PROD = 'https://api.montiscorp.com/';
const API_LOCAL = 'http://192.168.1.157:12345/';
const API_BASE: string = API_PROD;

const api = async (
  urlStr: string,
  method: 'GET' | 'PUT' | 'POST' | 'DELETE' = 'GET',
  body?: string,
  headers?: object,
  accessToken?: string
) => {
  let req;
  if (API_BASE === API_LOCAL) {
    req = await fetch(`${API_BASE}${urlStr}`, {
      method,
      body,
    });
  } else {
    if (!accessToken) {
      accessToken = await getAccessToken();
    }
    if (accessToken) {
      req = await fetch(`${API_BASE}${urlStr}`, {
        method,
        headers: {
          authorization: accessToken,
          ...headers,
        },
        body,
      });
    } else {
      console.error('accessToken cannot be undefined');
      return;
    }
  }
  try {
    const json = await req.json();
    return json;
  } catch (err) {
    console.error(err, 'error parsing json');
    return {};
  }
};

export const getStationInfo = async (stationId = 1): Promise<MwosDetails> => {
  const res = await api(`mwos/${stationId}`);
  return res;
};

export const getAircraft = async (center: LatLng, distNM = 5) => {
  const { latitude, longitude } = center;
  const url = `https://adsbexchange-com1.p.rapidapi.com/v2/lat/${latitude}/lon/${longitude}/dist/${distNM}/`;
  const options = {
    method: 'GET',
    headers: {
      'x-rapidapi-key': 'x6nAcyOtq9msh4dtmvJtOXBia7KFp1zvFgJjsnNsEWLYT8dX3F',
      'x-rapidapi-host': 'adsbexchange-com1.p.rapidapi.com',
    },
  };

  try {
    const response = await fetch(url, options);
    const result = await response.json();
    return result;
  } catch (error) {
    console.error(error);
  }
};

export const getMwosSitesOnPath = async (
  path: string,
  dist: number
): Promise<Mwos[]> => {
  const res = await api(`mwos?path=${path}&distanceMeters=${dist}`);
  return res;
};
export const memoGetMwosSitesOnPath = memoize(getMwosSitesOnPath, {
  maxAge: 60e3,
  cacheKey: JSON.stringify,
});

export const getMwosDetails = async (mwosId: number): Promise<MwosDetails> =>
  api(`mwos/${mwosId}`, 'GET', undefined, {}, API_TOKEN);
export const memoGetMwosDetails = memoize(getMwosDetails, {
  maxAge: 60e3,
  cacheKey: JSON.stringify,
});

export const getSitesOnPath = async (
  path: string,
  dist: number
): Promise<Site[]> => {
  const res = await api(`sites?path=${path}&distanceMeters=${dist}`);
  return res;
};
export const memoGetSitesOnPath = memoize(getSitesOnPath, {
  maxAge: 60e3,
  cacheKey: JSON.stringify,
});

export const getSiteDetails = async (siteId: number): Promise<SiteDetails> =>
  api(`sites/${siteId}`);
export const memoGetSiteDetails = memoize(getSiteDetails, {
  maxAge: 60e3,
});

export const getStationDetails = async (
  stationId: number
): Promise<StationDetails> => api(`stations/${stationId}`);
export const memoGetStationDetails = memoize(getStationDetails, {
  maxAge: 60e3,
});

export const getAirportsOnPath = async (
  path: string,
  dist: number
): Promise<Airport[]> => {
  const res = await api(`airports?path=${path}&distanceMeters=${dist}`);
  return res;
};
export const memoGetAirportsOnPath = memoize(getAirportsOnPath, {
  maxAge: 180e3,
  cacheKey: JSON.stringify,
});

export const searchAirports = async (query: string): Promise<SearchResponse> =>
  api(`search?query=${query}`);

export const getUserStations = async (): Promise<UserStation[]> =>
  api('u/stations');

export const memoGetUserStations = memoize(getUserStations, {
  maxAge: 60e3,
});

export const getSitesInBounds = async (
  latitude: number,
  longitude: number,
  radiusMeters: number
): Promise<Site[]> =>
  api(`sites?center=${latitude},${longitude}&distanceMeters=${radiusMeters}`);
export const memoGetSitesInBounds = memoize(getSitesInBounds, {
  maxAge: 180e3,
  cacheKey: JSON.stringify,
});

export const getStationsInBounds = async (
  latitude: number,
  longitude: number,
  radiusMeters: number
): Promise<Station[]> =>
  api(
    `stations?center=${latitude},${longitude}&distanceMeters=${radiusMeters}`
  );
export const memoGetStationsInBounds = memoize(getStationsInBounds, {
  maxAge: 180e3,
  cacheKey: JSON.stringify,
});

export const getAirportsInBounds = async (
  latitude: number,
  longitude: number,
  radiusMeters: number
): Promise<Airport[]> =>
  api(
    `airports?center=${latitude},${longitude}&distanceMeters=${radiusMeters}`
  );
export const memoGetAirportsInBounds = memoize(getAirportsInBounds, {
  maxAge: 180e3,
  cacheKey: JSON.stringify,
});

export const getMwosInBounds = async (
  latitude: number,
  longitude: number,
  radiusMeters: number
): Promise<Mwos[]> =>
  api(
    `mwos?center=${latitude},${longitude}&distanceMeters=${radiusMeters}`,
    'GET',
    undefined,
    undefined,
    API_TOKEN
  );
export const memoGetMwosInBounds = memoize(getMwosInBounds, {
  maxAge: 180e3,
  cacheKey: JSON.stringify,
});

export const saveUserStation = async (
  stationId: number,
  dynamodbId: string,
  stationType: 'mwos' | 'faa_camera_site' | 'weather_station'
) =>
  api(
    'u/stations',
    'POST',
    JSON.stringify({ stationId, dynamodbId, stationType })
  );

export const deleteUserStation = async (userStationId: number) =>
  api(`u/stations/${userStationId}`, 'DELETE');

export const saveUserRoute = async (r: NewRoute) =>
  api(`u/routes`, 'POST', JSON.stringify(r));

export const getUserRoutes = async () => api('u/routes');
export const memoGetUserRoutes = memoize(getUserRoutes, {
  maxAge: 15,
});
