import { css } from '@emotion/css';
import { useCallback, useEffect, useState } from 'react';
import { searchAirports } from '../../../api';
import IconAnchor from '../../svg-icons/Anchor';
import IconHelicopterSymbol from '../../svg-icons/HeliportSymbol';
import IconLocationDot from '../../svg-icons/LocationDot';
import PlaneUp from '../../svg-icons/PlaneUp';
import IconSearch from '../../svg-icons/Search';

type Props = {
  onClick: () => void;
  onResultClick: (r: any) => void;
};

type SearchResult = {
  type: string;
  id: number;
  icao: string;
  name: string;
  latitude: number;
  longitude: number;
};

export default function SearchButton({ onClick, onResultClick }: Props) {
  const [query, setQuery] = useState<string>('');
  const [results, setResults] = useState<SearchResult[]>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const search = useCallback(async (query: string) => {
    const results = await searchAirports(query);
    if (results?.length > 0) {
      setResults(results.slice(0, 10));
    }
  }, []);

  useEffect(() => {
    if (query.length > 2) {
      search(query);
    }
  }, [query]);

  const onSearchResultClick = useCallback(
    (result) => {
      onResultClick(result);
      setIsOpen(false);
    },
    [results]
  );

  return (
    <>
      <div className={styles}>
        <div className={`search ${isOpen ? 'open' : ''}`}>
          <div
            onClick={() => setIsOpen((x) => !x)}
            style={{
              display: 'flex',
              flexDirection: 'column',
              position: 'relative',
              alignItems: 'center',
              justifyContent: 'center',
              marginLeft: 12,
              width: 65,
            }}
          >
            <IconSearch width="34px" height="34px" />
            <label>Search</label>
          </div>
          <input
            type="text"
            spellCheck={false}
            onChange={(event) => setQuery(event.currentTarget.value)}
            placeholder="Search for..."
          />
        </div>
        {results.length > 0 && isOpen && (
          <div className={searchResultsStyle}>
            <ul>
              {results.map((r) => (
                <li key={r.id} onClick={() => onSearchResultClick(r)}>
                  <div className="icon">
                    {r.type === 'airport' && <PlaneUp />}
                    {r.type === 'heliport' && <IconHelicopterSymbol />}
                    {r.type === 'seaplane base' && <IconAnchor />}
                    {r.type === 'other' && <IconLocationDot />}
                  </div>
                  {r.name} ({r.icao})
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </>
  );
}

const styles = css({
  display: 'flex',
  flexDirection: 'column',
  '.search': {
    backgroundColor: '#ffffffdd',
    width: '65px',
    height: '65px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    borderRadius: '3px',
    overflow: 'hidden',
    color: '#333',
    ':hover': {
      opacity: 0.85,
      cursor: 'pointer',
    },
    '&.open': {
      ':hover': {
        opacity: 1,
      },
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      // transition: '.25s',
      width: 325,
      input: {
        borderBottom: '1px solid #999',
      },
    },
  },
  input: {
    position: 'relative',
    height: 40,
    left: 20,
    width: 200,
    backgroundColor: '#00000000',
    border: 'none',
    outline: 'none',
    ':hover,:active': {
      borderBottom: '1px solid #999',
    },
  },
  label: {
    fontSize: 14,
    textAlign: 'center',
  },
  '@media (max-width: 600px)': {
    width: 50,
    height: 50,
    label: {
      fontSize: 12,
    },
  },
});

const searchResultsStyle = css({
  backgroundColor: '#ffffffdd',
  padding: '5px 0px',
  width: 325,
  zIndex: 1,
  borderBottomLeftRadius: 3,
  borderBottomRightRadius: 3,
  ul: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
  },
  li: {
    padding: '5px 15px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyItems: 'flex-start',
    fontSize: 14,
    cursor: 'pointer',
    ':hover': {
      backgroundColor: '#0000000a',
    },
  },
  '.icon': {
    minWidth: 20,
    color: '#333',
    marginRight: 5,
    paddingTop: 4,
  },
});
