import { Entity, Viewer } from 'cesium';
import {
  memoGetAirportsInBounds,
  memoGetAirportsOnPath,
  memoGetMwosDetails,
  memoGetMwosInBounds,
  memoGetMwosSitesOnPath,
  memoGetSiteDetails,
  memoGetSitesInBounds,
  memoGetSitesOnPath,
  memoGetStationDetails,
  memoGetStationsInBounds,
} from '../../api';
import {
  Airport,
  MwosDetails,
  SiteDetails,
  Station,
  StationDetails,
} from '../../api/types';
import {
  LatLng,
  MapUndoAction,
  MapUndoActionType,
  Route,
} from '../../types/types';
import {
  createAirportEntity,
  createMwosEntity,
  createStationEntity,
} from './cesium';

export const executeUndoAction = (
  undoActions: MapUndoAction[],
  route: Route,
  setRoute: (r: Route) => void
): MapUndoAction[] => {
  const undos = undoActions;
  const last = undos.pop();
  if (last?.type === MapUndoActionType.MoveRouteMidpoint) {
    setRoute({
      ...route,
      path: route.path.filter((point) => point !== last.next),
    });
  } else if (last?.type === MapUndoActionType.MoveRoutePoint) {
    setRoute({
      ...route,
      path: route.path.map((point) => {
        if (JSON.stringify(point) === JSON.stringify(last.next)) {
          return last.prev;
        }
        return point;
      }),
    });
  } else if (last?.type === MapUndoActionType.ChangeCorridorWidth) {
    setRoute({
      ...route,
      corridor: last.prev,
    });
  } else if (last?.type === MapUndoActionType.AddRoutePoint) {
    if (route.path.length === 1) {
      setRoute({
        ...route,
        path: [],
      });
    } else {
      setRoute({
        ...route,
        path: route.path.slice(0, route.path.length - 1),
      });
    }
  }
  return undoActions;
};

type AirportsAndEntities = {
  airports: Airport[];
  entities: Entity[];
};

type RefreshAndDrawRouteAirportsProps = {
  viewer: Viewer;
  route: Route;
  airportEntities: Entity[];
};

export const refreshAndDrawRouteAirports = async ({
  viewer,
  route,
}: RefreshAndDrawRouteAirportsProps): Promise<AirportsAndEntities> => {
  const path = route.path
    .map((p: LatLng) => `${p.latitude},${p.longitude}`)
    .join(',');

  const dist = (route.corridor / 2) * 1609.344;
  let airports = await memoGetAirportsOnPath(path, dist);

  if (!airports || !Array.isArray(airports)) {
    return {
      airports: [],
      entities: [],
    };
  }

  airports = airports.filter((a) => a.hasControlTower);

  for (const e of viewer.entities.values) {
    if (e.properties?.hasProperty('airportDetails')) {
      viewer.entities.remove(e);
    }
  }

  const newAirportEntities = [];
  for (const a of airports) {
    const e = createAirportEntity(a);
    newAirportEntities.push(e);
    viewer.entities.add(e);
  }

  return {
    airports,
    entities: newAirportEntities,
  };
};

type RefreshAndDrawRouteMwosProps = {
  viewer: Viewer;
  route: Route;
  mwosEntities: Entity[];
};

export const refreshAndDrawRouteMwos = async ({
  viewer,
  route,
  mwosEntities,
}: RefreshAndDrawRouteMwosProps): Promise<Entity[]> => {
  const path = route.path.map((p) => `${p.latitude},${p.longitude}`).join(',');
  const dist = (route.corridor / 2) * 1609.344;
  const mwos = await memoGetMwosSitesOnPath(path, dist);

  if (!mwos || !Array.isArray(mwos)) {
    return [];
  }

  const withDetails: MwosDetails[] = [];
  for (const m of mwos) {
    const details = await memoGetMwosDetails(m.id);
    // m.cameras = details.cameras;
    // m.observations = details.observations;
    withDetails.push(details);
  }

  for (const m of mwosEntities) {
    viewer.entities.remove(m);
  }
  const newMwosEntities = [];
  for (const m of mwos) {
    const e = createMwosEntity(m);
    newMwosEntities.push(e);
    viewer.entities.add(e);
  }

  return newMwosEntities;
};

type RefreshStationsProps = {
  viewer: Viewer;
  route: Route;
  stationEntities: Entity[];
};

type StationsAndEntities = {
  stations: Station[];
  entities: Entity[];
};

export const refreshAndDrawRouteStations = async ({
  viewer,
  route,
}: RefreshStationsProps): Promise<StationsAndEntities> => {
  const path = route.path.map((p) => `${p.latitude},${p.longitude}`).join(',');
  const dist = (route.corridor / 2) * 1609.344;

  const sites = await memoGetSitesOnPath(path, dist);
  if (!sites || !Array.isArray(sites)) {
    return {
      stations: [],
      entities: [],
    };
  }

  const stations = [];
  await Promise.all(
    sites.map(async (site) => {
      const details = await memoGetSiteDetails(site.id);
      site.cameras = details.cameras;

      if (site.stationId) {
        const station = await memoGetStationDetails(site.stationId);
        site.metar = station.metar;
      }

      stations.push(site);
    })
  );

  for (const e of viewer.entities.values) {
    if (e.properties?.hasProperty('stationDetails')) {
      viewer.entities.remove(e);
    }
  }

  const entities = [];
  for (const station of sites) {
    const e = createStationEntity(station);
    entities.push(e);
    viewer.entities.add(e);
  }

  return { stations, entities };
};

export const getScreenSites = async (
  pos: LatLng,
  dist: number
): Promise<SiteDetails[]> => {
  const sites = await memoGetSitesInBounds(
    pos.latitude * 57.295,
    pos.longitude * 57.295,
    dist * 2.5
  );

  if (!Array.isArray(sites)) {
    return [];
  }
  const sitesWithDetails: SiteDetails[] = [];

  sites.forEach((site) => {
    sitesWithDetails.push({
      ...site,
      stationType: 'faa_camera_site',
    });
  });

  return sitesWithDetails;
};

export const getScreenStations = async (
  pos: LatLng,
  dist: number
): Promise<StationDetails[]> => {
  const stations = await memoGetStationsInBounds(
    pos.latitude * 57.295,
    pos.longitude * 57.295,
    dist * 2.5
  );
  if (!Array.isArray(stations)) {
    return [];
  }
  const withDetails: StationDetails[] = [];
  stations.forEach(async (s) => {
    // const details = await memoGetStationDetails(s.id);
    // s.metar = details.metar;
    withDetails.push({
      ...s,
      stationType: 'weather_station',
    });
  });

  return withDetails;
};

export const getScreenAirports = async (
  pos: LatLng,
  dist: number
): Promise<Airport[]> => {
  const airports = await memoGetAirportsInBounds(
    pos.latitude * 57.295,
    pos.longitude * 57.295,
    dist
  );
  if (!Array.isArray(airports)) {
    return [];
  }
  return airports.filter((a) => a.icao?.length);
};

export const getScreenMwos = async (
  pos: LatLng,
  dist: number
): Promise<MwosDetails[]> => {
  const mwos = await memoGetMwosInBounds(
    pos.latitude * 57.295,
    pos.longitude * 57.295,
    dist
  );
  if (!Array.isArray(mwos)) {
    return [];
  }
  const withDetails: MwosDetails[] = [];
  await Promise.all(
    mwos.map(async (m) => {
      const details = await memoGetMwosDetails(m.id);
      withDetails.push({
        ...details,
        stationType: 'mwos',
      });
    })
  );
  return withDetails;
};

// Fly the route!
//
// if (mapState.route.path.length > 10) {
//   const points = [
//     ...mapState.route.path.map((p) => [p.longitude, p.latitude]),
//   ];
//   // console.log('points', points);
//   const ls = lineString(points);
//   // console.log('lineString', lineString);
//   // console.log('along', along(ls, 5, { units: 'miles' }));
//   let totalDistance = 0;
//   for (let i = 0; i < lineString.length; i += 1) {
//     // console.log(ls[i]);
//     totalDistance += distance(
//       point(ls.geometry.coordinates[0]),
//       point(ls.geometry.coordinates[1])
//     );
//   }
//   let dist = 0;
//   const next = () => {
//     // console.log({ dist, totalDistance });
//     if (dist >= totalDistance - 5) {
//       return;
//     }
//     const pt = along(ls, dist, { units: 'miles' });
//     const e = createAircraftEntity({
//       label: '',
//       longitude: pt.geometry.coordinates[0],
//       latitude: pt.geometry.coordinates[1],
//       altitude: 5000,
//       color: Color.TRANSPARENT,
//     });
//     viewer.entities.add(e);

//     const lookAtPoint = along(ls, dist + 1, { units: 'miles' });

//     const camera = viewer.camera;
//     const cameraPosition = viewer.camera.position.clone();
//     let direction = Cartesian3.subtract(
//       Cartesian3.fromDegrees(
//         lookAtPoint.geometry.coordinates[0],
//         lookAtPoint.geometry.coordinates[1],
//         100
//       ),
//       cameraPosition,
//       new Cartesian3()
//     );
//     direction = Cartesian3.normalize(direction, direction);
//     viewer.camera.direction = direction;

//     // get an "approximate" up vector, which in this case we want to be something like the geodetic surface normal.
//     const approxUp = Cartesian3.normalize(cameraPosition, new Cartesian3());

//     // cross viewdir with approxUp to get a right normal
//     let right = Cartesian3.cross(direction, approxUp, new Cartesian3());
//     right = Cartesian3.normalize(right, right);
//     viewer.camera.right = right;

//     // cross right with view dir to get an orthonormal up
//     let up = Cartesian3.cross(right, direction, new Cartesian3());
//     up = Cartesian3.normalize(up, up);
//     camera.up = up;

//     viewer.camera.flyTo({
//       destination: Cartesian3.fromDegrees(
//         pt.geometry.coordinates[0],
//         pt.geometry.coordinates[1],
//         1e3
//       ),
//       orientation: {
//         direction,
//         up,
//       },
//       duration: 1,
//       complete: () => {
//         dist += 5;
//         if (dist < totalDistance - 5) {
//           next();
//         }
//       },
//       easingFunction: EasingFunction.LINEAR_NONE,
//     });

//     // dist += 5;
//   };
//   next();
//   console.log('totalDistance', totalDistance);
// }
