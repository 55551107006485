import 'cesium/Build/Cesium/Widgets/widgets.css';
import { useState } from 'react';
import useStore from '../../state/state';
import { Route } from '../../types/types';
import Controls from './controls/Controls';
import Map from './Map';
import { emptyRoute } from './map-data';

export default function MapView() {
  const [setAircraft, setSetAircraft] = useState();
  const [setCorridor, setSetCorridor] = useState();
  const [doFlyTo, setFlyTo] = useState({});
  const [doUndo, setDoUndo] = useState({});
  const [doMeasureRoute, setDoMeasureRoute] = useState({});
  const [setHeading, setSetHeading] = useState({});
  const [route, setRoute] = useState<Route>(emptyRoute);
  const [_setRoute, setSetRoute] = useState({});
  const [setLocked, setSetLocked] = useState({});
  const mapUndoActions = useStore((store) => store.mapUndoActions);
  const setMapUndoActions = useStore((store) => store.setMapUndoActions);

  return (
    <>
      <Map
        route={route}
        setRoute={setRoute}
        setSetAircraft={setSetAircraft}
        setSetCorridor={setSetCorridor}
        setDoUndo={setDoUndo}
        setFlyTo={setFlyTo}
        setDoMeasureRoute={setDoMeasureRoute}
        setSetRoute={setSetRoute}
        setSetLocked={setSetLocked}
        setSetHeading={setSetHeading}
        setUndoActions={setMapUndoActions}
      />
      <Controls
        route={route}
        setRoute={_setRoute.set}
        setRouteLocked={setLocked.set}
        setCorridor={setCorridor}
        setAircraft={setAircraft}
        undo={doUndo.do}
        undoActions={mapUndoActions}
        flyTo={doFlyTo.do}
        measureRoute={doMeasureRoute.do}
        setHeading={setHeading.set}
      />
    </>
  );
}
