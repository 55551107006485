import { css } from '@emotion/css';
import { radiansToDegrees } from '@turf/helpers';
import compassImg from '../../../assets/images/compass.svg';
import useStore from '../../../state/state';

export default function Compass({ onClick }) {
  const heading = useStore((store) => store.heading);
  const orientationDegrees = radiansToDegrees(heading);
  return (
    <div
      className={style}
      style={{
        transform: `rotateZ(-${orientationDegrees}deg)`,
      }}
      onClick={onClick}
    >
      {/* <span className="N"></span> */}
      {/* 
      <span className="S">S</span>
      <span className="E">E</span>
      <span className="W">W</span>
      <span className="circle" /> */}
      <img src={compassImg} />
    </div>
  );
}

const style = css({
  position: 'absolute',
  width: 60,
  height: 60,
  bottom: 10,
  right: 10,
  backgroundColor: '#ffffff77',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 75,
  color: '#111',
  ':hover': {
    cursor: 'pointer',
    backgroundColor: '#ffffff99',
  },
  img: {
    height: '95%',
  },
  '.N,.S,.E,.W': {
    position: 'absolute',
    // padding: 5,
    fontWeight: 400,
    fontSize: '1em',
    // fontFamily: 'monospace',
  },
  '.N': {
    color: '#dd0000',
    fontWeight: 700,
    ':before': {
      content: '""',
      width: 10,
      height: 10,
      transform: 'translateX(1px) translateY(-8px) rotateZ(45deg)',
      position: 'absolute',
      top: 0,
      borderBottom: '10px solid transparent',
      borderLeft: '10px solid #dd0000dd',
    },
    top: 0,
  },
  '.S': {
    bottom: 0,
  },
  '.W': {
    left: 0,
  },
  '.E': {
    right: 0,
  },
  '.circle': {
    width: 7,
    height: 7,
    borderRadius: 10,
    border: '1px solid #000',
  },
});
