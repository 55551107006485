import { css } from '@emotion/css';
import IconRestart from '../../svg-icons/Restart';
import Button from './Button';

export default function ClearButton({ onClick }) {
  return (
    <Button
      icon={<IconRestart />}
      text="Reset"
      onClick={onClick}
      className={styles}
    />
  );
}

const styles = css({
  svg: {
    transform: 'rotateY(180deg) translateY(1px)',
  },
});
