import { useAuthenticator } from '@aws-amplify/ui-react';
import { css } from '@emotion/css';
import { useCallback, useEffect, useState } from 'react';
import { getStationDetails, getUserStations } from '../../../api';
import { getRoutes, LegacyRoute } from '../../../api/legacy';
import { colors } from '../../../constants/colors';
import { MapSettings, MarkerType, Station } from '../../../types/types';
import Drawer from '../../Drawer';
import ToggleSwitch from '../../ToggleSwitch';
import MenuIcon from '../../svg-icons/Menu';

import stationIfrIcon from '../../../assets/images/station_ifr.svg';
import stationLifrIcon from '../../../assets/images/station_lifr.svg';
import stationMvfrIcon from '../../../assets/images/station_mvfr.svg';
import stationPlainIcon from '../../../assets/images/station_plain.svg';
import stationVfrIcon from '../../../assets/images/station_vfr.svg';

const RoutesSection = ({ onSelectRoute }) => {
  const [routes, setRoutes] = useState<LegacyRoute[]>([]);

  const loadRoutes = useCallback(async () => {
    const r = await getRoutes();
    if (r?.count > 0) {
      setRoutes(r.results);
    }
  }, []);

  useEffect(() => {
    if (routes.length === 0) {
      loadRoutes();
    }
  });

  if (routes.length === 0) {
    return (
      <div className="section routes">
        <span className="title">Saved Routes</span>
        <br />
        <span>Loading routes...</span>
      </div>
    );
  }
  return (
    <div className="section routes">
      <span className="title">Saved Routes</span>
      <ul>
        {routes.map((r) => (
          <li
            key={r.Id}
            onClick={() => {
              onSelectRoute(r);
            }}
          >
            {r.Name}
          </li>
        ))}
      </ul>
    </div>
  );
};

const StationsSection = ({ onSelectStation }) => {
  const [stations, setStations] = useState<Station[]>([]);

  const load = useCallback(async () => {
    const s = await getUserStations();
    console.log(s);
    if (s?.length > 0) {
      const withDetails = [];
      for (const station of s) {
        withDetails.push(await getStationDetails(station.id));
      }
      setStations(s);
    }
  }, []);

  useEffect(() => {
    if (stations.length === 0) {
      load();
    }
  }, []);

  // load();

  if (stations.length === 0) {
    return (
      <div className="section stations">
        <span className="title">Saved Stations</span>
        <br />
        <span>Loading stations...</span>
      </div>
    );
  }
  return (
    <div className="section stations">
      <span className="title">Saved Stations</span>
      <ul>
        {stations.map((s) => (
          <li
            key={s.id}
            onClick={() => {
              onSelectStation(s);
            }}
          >
            {s.siteName}
          </li>
        ))}
      </ul>
    </div>
  );
};

type StationTogglesProps = {
  showMarkers: {
    [key in MarkerType]: boolean;
  };
  updateShowMarkers: (k: MarkerType, v: boolean) => void;
};

const StationToggles = ({
  showMarkers,
  updateShowMarkers,
}: StationTogglesProps) => {
  const updateVfr = (value: boolean) => updateShowMarkers('vfr', value);
  const updateMvfr = (value: boolean) => updateShowMarkers('mvfr', value);
  const updateLifr = (value: boolean) => updateShowMarkers('lifr', value);
  const updateIfr = (value: boolean) => updateShowMarkers('ifr', value);
  const updateNoFlightCategory = (value: boolean) =>
    updateShowMarkers('noFlightCategory', value);

  return (
    <>
      <li style={{ paddingLeft: '1em' }}>
        <span style={{ display: 'flex', flexDirection: 'row' }}>
          <img
            src={stationVfrIcon}
            style={{ width: '1em', marginRight: '.5em' }}
          />
          VFR
        </span>
        <ToggleSwitch value={showMarkers.vfr} onChange={updateVfr} />
      </li>
      <li style={{ paddingLeft: '1em' }}>
        <span style={{ display: 'flex', flexDirection: 'row' }}>
          <img
            src={stationMvfrIcon}
            style={{ width: '1em', marginRight: '.5em' }}
          />
          MVFR
        </span>
        <ToggleSwitch value={showMarkers.mvfr} onChange={updateMvfr} />
      </li>
      <li style={{ paddingLeft: '1em' }}>
        <span style={{ display: 'flex', flexDirection: 'row' }}>
          <img
            src={stationLifrIcon}
            style={{ width: '1em', marginRight: '.5em' }}
          />
          LIFR
        </span>
        <ToggleSwitch value={showMarkers.lifr} onChange={updateLifr} />
      </li>
      <li style={{ paddingLeft: '1em' }}>
        <span style={{ display: 'flex', flexDirection: 'row' }}>
          <img
            src={stationIfrIcon}
            style={{ width: '1em', marginRight: '.5em' }}
          />
          IFR
        </span>{' '}
        <ToggleSwitch value={showMarkers.ifr} onChange={updateIfr} />
      </li>
      <li style={{ paddingLeft: '1em' }}>
        <span style={{ display: 'flex', flexDirection: 'row' }}>
          <img
            src={stationPlainIcon}
            style={{ width: '1em', marginRight: '.5em' }}
          />
          No flight category
        </span>{' '}
        <ToggleSwitch
          value={showMarkers.noFlightCategory}
          onChange={updateNoFlightCategory}
        />
      </li>
    </>
  );
};

type Props = {
  isOpen: boolean;
  close: () => void;
  mapSettings: MapSettings;
  setMapSettings: (s: MapSettings) => void;
};

export default function MapSettingsDrawer({
  isOpen,
  close,
  mapSettings,
  setMapSettings,
}: Props) {
  const auth = useAuthenticator();

  const signOut = useCallback(() => {
    auth.signOut();
  }, [auth]);

  const updateShowMarkers = (
    key: 'airports' | 'cameraSites' | 'weatherStations' | 'vfr',
    value: boolean
  ) => {
    mapSettings.showMarkers[key] = value;
    setMapSettings({ ...mapSettings });
  };
  const updateAirports = (value: boolean) =>
    updateShowMarkers('airports', value);

  const updateCameraSites = (value: boolean) =>
    updateShowMarkers('cameraSites', value);

  const updateWeatherStations = (value: boolean) =>
    updateShowMarkers('weatherStations', value);

  if (!isOpen) {
    return false;
  }

  return (
    <Drawer
      screenSide="right"
      isOpen={isOpen}
      onClose={close}
      title="Map Menu"
      className={style}
      icon={<MenuIcon />}
    >
      <div className="section">
        <span className="title">Display Icons</span>
        <ul>
          <li>
            Airports{' '}
            <ToggleSwitch
              value={mapSettings.showMarkers.airports}
              onChange={updateAirports}
            />
          </li>
          <li>
            Camera Sites{' '}
            <ToggleSwitch
              value={mapSettings.showMarkers.cameraSites}
              onChange={updateCameraSites}
            />
          </li>
          <li>
            Weather Stations{' '}
            <ToggleSwitch
              value={mapSettings.showMarkers.weatherStations}
              onChange={updateWeatherStations}
            />
          </li>
          {mapSettings.showMarkers.weatherStations && (
            <StationToggles
              showMarkers={mapSettings.showMarkers}
              updateShowMarkers={updateShowMarkers}
            />
          )}
        </ul>
      </div>

      <button onClick={signOut} className="sign-out">
        Sign Out
      </button>
    </Drawer>
  );
}

const style = css({
  'button.sign-out': {
    padding: '.5em 1em',
    backgroundColor: colors.primaryLight,
    color: 'white',
    borderRadius: 5,
    // marginRight: 10,
    position: 'absolute',
    right: '1em',
    bottom: '1em',
    fontWeight: 600,
    outline: 'none',
    borderWidth: 0,
    fontSize: '1.25em',
    cursor: 'pointer',
    ':hover': {
      opacity: 0.9,
    },
  },
  ul: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
    marginTop: 10,
    li: {
      fontWeight: 400,
      margin: 0,
      padding: 0,
      fontSize: '1.15em',
      marginBottom: 5,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },
  '.section': {
    // marginTop: 20,
    marginBottom: 20,
    paddingBottom: 20,
    '.title': {
      fontSize: '1.5em',
      fontWeight: 600,
    },
    borderBottom: '1px solid #aaa',
    '@media (max-width: 600px)': {
      fontSize: 13,
    },
  },
  '.section:last-child': {
    borderBottom: 'none',
  },
  '.section.routes, .section.stations': {
    // flex: 1,
    ul: {
      maxHeight: 200,
      overflow: 'scroll',
    },
    li: {
      cursor: 'pointer',
      padding: '2px 0px',
      ':hover': {
        backgroundColor: '#0000000a',
        color: colors.primaryLight,
      },
    },
  },
  '@media (max-width: 600px)': {
    width: 280,
    button: {
      fontSize: 15,
    },
  },
});
