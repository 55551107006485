import { css } from '@emotion/css';
import { colors } from '../../../constants/colors';
import useStore from '../../../state/state';
import { Aircraft } from '../../../types/types';
import Drawer from '../../Drawer';
import IconPlaneTilt from '../../svg-icons/Plane';

type Props = {
  isOpen: boolean;
  onSelect: (a: Aircraft) => void;
  onClose: () => void;
};

export default function AdsbDrawer({ isOpen, onSelect, onClose }: Props) {
  const aircraft = useStore((store) => store.aircraft);
  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      icon={<IconPlaneTilt />}
      title="ADS-B"
      screenSide="left"
      className={style}
    >
      <span className="title">Tracked Aircraft</span>
      <ul>
        {aircraft
          .filter((a) => a.flight?.length > 0)
          .map((a) => {
            return (
              <li key={`${a.lat},${a.lon}`} onClick={() => onSelect(a)}>
                <strong>{a.flight}</strong> {a.lat.toFixed(2)},{' '}
                {a.lon.toFixed(2)}
              </li>
            );
          })}
      </ul>
    </Drawer>
  );
}

const style = css({
  '.title': {
    fontSize: '1.5em',
    fontWeight: 600,
  },
  ul: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
    marginTop: '1em',
    li: {
      fontWeight: 400,
      margin: 0,
      fontSize: '1.15em',
      marginBottom: 5,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      cursor: 'pointer',
      padding: '2px 0px',
      ':hover': {
        backgroundColor: '#0000000a',
        color: colors.primaryLight,
      },
    },
  },
});
