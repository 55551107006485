import { css } from '@emotion/css';
import Pano from '@montiscorp/pano';

export default function PanoramaTab({ station }) {
  if (station.id !== 1 && station.id !== 2) {
    return false;
  }
  return (
    <div className={styles}>
      <Pano
        stationId={station.id}
        config={{
          controls: { showStationInfo: false },
          // labels: [
          //   {
          //     name: 'Ship Creek Hill NUMBER2',
          //     elevation: 1172,
          //     latitude: 61.2067,
          //     longitude: -149.561,
          //   },
          // ],
        }}
      />
    </div>
  );
}

const styles = css({
  aspectRatio: 1,
  width: '100%',
  position: 'relative',
  minHeight: '60vh',
});
