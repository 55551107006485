import { css } from '@emotion/css';
import RoutesIcon from '../../../assets/images/route_light.svg';

type Props = {
  onClick: () => void;
};

export default function RoutesButton({ onClick }: Props) {
  return (
    <div className={styles} onClick={onClick}>
      <img src={RoutesIcon} width="35px" height="35px" />
      <label>{'Routes'}</label>
    </div>
  );
}

const styles = css({
  backgroundColor: '#ffffffdd',
  width: '65px',
  height: '65px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 3,
  color: '#222',
  paddingTop: 5,
  label: {
    fontSize: 14,
  },
  ':hover': {
    opacity: 0.85,
    cursor: 'pointer',
  },
  '@media (max-width: 600px)': {
    width: 50,
    height: 50,
    label: {
      fontSize: 11,
    },
  },
});
