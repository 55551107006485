import { css } from '@emotion/css';
import UndoIcon from '../../svg-icons/Undo';
import Button from './Button';

type Props = {
  onClick: () => void;
};

export default function UndoButton({ onClick }: Props) {
  return (
    <Button
      icon={<UndoIcon />}
      text="Undo"
      className={styles}
      onClick={onClick}
    />
  );
}

const styles = css({
  svg: {
    width: 29,
    height: 29,
  },
});
