import { css } from '@emotion/css';
import IconMenu from '../../svg-icons/Menu';

type Props = {
  onClick: () => void;
};

export default function MenuButton({ onClick }: Props) {
  return (
    <div className={styles} onClick={onClick}>
      <IconMenu width="35px" height="35px" />
      <label>Menu</label>
    </div>
  );
}

const styles = css({
  // position: 'absolute',
  // top: 10,
  // right: 10,
  backgroundColor: '#ffffffdd',
  width: '65px',
  height: '65px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 3,
  paddingTop: 4,
  label: {
    fontSize: 14,
  },
  ':hover': {
    opacity: 0.85,
    cursor: 'pointer',
  },
  '@media (max-width: 600px)': {
    width: 50,
    height: 50,
    label: {
      fontSize: 11,
    },
  },
});
