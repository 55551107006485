import { minutesAgo } from './functions';

export default function SingleImage({ camera, isMwos }) {
  return (
    <div className="image-wrapper">
      <img
        src={camera.currentImageUrl}
        style={{ width: '100%', padding: 2, backgroundColor: '#ffffffdd' }}
      />
      <span className="sub">
        {isMwos ? camera.directionText : camera.cameraDirection} (
        {minutesAgo(
          isMwos ? camera.currentImageObservationTime : camera.cameraLastSuccess
        )}{' '}
        min ago)
      </span>
    </div>
  );
}
