import {
  ArcType,
  Cartesian2,
  Cartesian3,
  Cartographic,
  Color,
  Ellipsoid,
  Entity,
  HeightReference,
  LabelStyle,
  Math,
  NearFarScalar,
  PositionProperty,
  VerticalOrigin,
  Viewer,
} from 'cesium';
import { LatLng, Route } from '../../types/types';

import airportTower from '../../assets/images/airport-with-tower.svg';
import airport from '../../assets/images/airport.svg';
import airportPrivate from '../../assets/images/airports-private.svg';
import airportSeaport from '../../assets/images/airports-seaport.svg';
import mwosIcon from '../../assets/images/mwos.svg';
import routePoint from '../../assets/images/route_point.svg';
import ifr from '../../assets/images/station_ifr.svg';
import ifrCamera from '../../assets/images/station_ifr_cam.svg';
import lifr from '../../assets/images/station_lifr.svg';
import lifrCamera from '../../assets/images/station_lifr_cam.svg';
import mvfr from '../../assets/images/station_mvfr.svg';
import mvfrCamera from '../../assets/images/station_mvfr_cam.svg';
import plain from '../../assets/images/station_plain.svg';
import plainCamera from '../../assets/images/station_plain_cam.svg';
import vfr from '../../assets/images/station_vfr.svg';
import vfrCamera from '../../assets/images/station_vfr_cam.svg';

import { radiansToDegrees } from '@turf/helpers';
import { MwosDetails, SiteDetails, StationDetails } from '../../api/types';
import { calcBuffer } from '../../utils/map';

const billboardScaleByDistance = new NearFarScalar(1.2e3, 1.0, 2e6, 0.1);
const billboardTranslucencyByDistance = new NearFarScalar(2e5, 1.0, 1e8, 0);
const labelScaleByDistance = new NearFarScalar(1.2e3, 1.0, 2e6, 0.25);
const labelTranslucencyByDistance = new NearFarScalar(2e5, 1.0, 1e8, 0);
const labelPixelOffsetByDistance = new NearFarScalar(1.2e3, 1.0, 2e6, 0.25);

export const entityPosInDegrees = (e: Entity): LatLng | undefined => {
  const pos = (e.position as PositionProperty).getValue();
  if (pos === undefined) {
    console.error('entity has no position property', e);
    return undefined;
  }
  const cartographicPos = Cartographic.fromCartesian(pos);
  return {
    latitude: radiansToDegrees(cartographicPos.latitude),
    longitude: radiansToDegrees(cartographicPos.longitude),
  };
};

export const createStationEntity = (
  station: StationDetails | SiteDetails
): Entity => {
  let image;
  if (!station.flightCategory) {
    if (station.stationType === 'faa_camera_site') {
      image = plainCamera;
    } else {
      image = plain;
    }
  } else {
    if (station.stationType === 'faa_camera_site') {
      switch (station.flightCategory) {
        case 'VFR':
          image = vfrCamera;
          break;
        case 'IFR':
          image = ifrCamera;
          break;
        case 'MVFR':
          image = mvfrCamera;
          break;
        case 'LIFR':
          image = lifrCamera;
          break;
      }
    } else {
      switch (station.flightCategory) {
        case 'VFR':
          image = vfr;
          break;
        case 'IFR':
          image = ifr;
          break;
        case 'MVFR':
          image = mvfr;
          break;
        case 'LIFR':
          image = lifr;
          break;
      }
    }
  }

  return new Entity({
    position: Cartesian3.fromDegrees(station.longitude, station.latitude, 50),
    billboard: {
      heightReference: HeightReference.CLAMP_TO_TERRAIN,
      disableDepthTestDistance: Number.POSITIVE_INFINITY,
      image: image,
      width: 48,
      height: 48,
      scaleByDistance: billboardScaleByDistance,
      translucencyByDistance: billboardTranslucencyByDistance,
    },
    name: station.icao || station.icaoId || station.siteName,
    label: {
      text: station.icao || station.icaoId || station.siteName,
      font: 'bold 16pt "Open Sans", sans-serif',
      verticalOrigin: VerticalOrigin.BASELINE,
      pixelOffset: new Cartesian2(0, 45),
      pixelOffsetScaleByDistance: labelPixelOffsetByDistance,
      heightReference: HeightReference.CLAMP_TO_TERRAIN,
      scaleByDistance: labelScaleByDistance,
      disableDepthTestDistance: Number.POSITIVE_INFINITY,
      translucencyByDistance: labelTranslucencyByDistance,
      fillColor: Color.WHITE,
      outlineColor: Color.BLACK,
      outlineWidth: 1,
      style: LabelStyle.FILL_AND_OUTLINE,
    },
    properties: {
      stationDetails: station,
      entityType: 'AWOS',
    },
  });
};

export const createMwosEntity = (mwos: MwosDetails): Entity => {
  if (!mwos || !mwos.latitude) {
    console.error('cannot create MWOS entity');
    return new Entity();
  }
  return new Entity({
    position: Cartesian3.fromDegrees(mwos.longitude, mwos.latitude, 30),
    billboard: {
      heightReference: HeightReference.CLAMP_TO_TERRAIN,
      disableDepthTestDistance: Number.POSITIVE_INFINITY,
      image: mwosIcon,
      width: 48,
      height: 48,
      scaleByDistance: billboardScaleByDistance,
      translucencyByDistance: billboardTranslucencyByDistance,
    },
    name: mwos.icaoId,
    label: {
      text: mwos.icaoId,
      font: 'bold 16pt "Open Sans", sans-serif',
      verticalOrigin: VerticalOrigin.BASELINE,
      heightReference: HeightReference.CLAMP_TO_TERRAIN,
      pixelOffset: new Cartesian2(0, 45),
      pixelOffsetScaleByDistance: labelPixelOffsetByDistance,
      disableDepthTestDistance: Number.POSITIVE_INFINITY,
      scaleByDistance: labelScaleByDistance,
      translucencyByDistance: labelTranslucencyByDistance,
      fillColor: Color.WHITE,
      outlineColor: Color.BLACK,
      outlineWidth: 1,
      style: LabelStyle.FILL_AND_OUTLINE,
    },
    properties: {
      stationDetails: mwos,
      entityType: 'MWOS',
    },
  });
};

type createAircraftProps = {
  label: string;
  latitude: number;
  longitude: number;
  altitude: number;
  color: Color;
};
export const createAircraftEntity = ({
  label,
  latitude,
  longitude,
  altitude,
  color,
}: createAircraftProps): Entity => {
  return new Entity({
    position: Cartesian3.fromDegrees(longitude, latitude, altitude + 20),
    ellipsoid: {
      radii: new Cartesian3(25, 25, 25),
      material: color || Color.RED,
      heightReference: HeightReference.RELATIVE_TO_TERRAIN,
    },
    name: label,
    label: {
      text: label,
      font: 'bold 12pt "Open Sans", sans-serif',
      verticalOrigin: VerticalOrigin.TOP,
      pixelOffset: new Cartesian2(0, 5),
      // heightReference: HeightReference.RELATIVE_TO_TERRAIN,
      disableDepthTestDistance: 1e5,
      // disableDepthTestDistance: 1e5,
      scaleByDistance: labelScaleByDistance,
      translucencyByDistance: labelTranslucencyByDistance,
      fillColor: Color.WHITE,
      outlineColor: Color.BLACK,
      outlineWidth: 1,
      style: LabelStyle.FILL_AND_OUTLINE,
    },
  });
};

type Airport = {
  id: number;
  designator: string;
  icao: string;
  name: string;
  latitude: number;
  longitude: number;
  hasControlTower: boolean;
  type: 'AH' | 'AP' | 'OTHER';
  privateUse: boolean;
};

const airportImage = (a: Airport) => {
  // console.log('airport', a);
  if (a.type === 'AH') {
    if (a.hasControlTower) {
      return airportTower;
    }
    return airport;
  }
  // if (a.type === 'AH') {
  //   return airportHeliport
  // }
  if (a.type === 'OTHER') {
    return airportSeaport;
  }
  if (a.privateUse) {
    return airportPrivate;
  }
  return airport;
};

const airportLabel = (a: Airport) => {
  // console.log('airport', a)
  let label = '';
  // if (a.name.length > 0) {
  //   label += a.name
  // }
  // if (a.designator.length > 0) {
  //   label += ` (${a.designator})`;
  // }
  // if (a.icao.length > 0) {
  //   label += ` (${a.icao})`;
  // }
  label = a.icao;
  return label;
};

export const createAirportEntity = (a: Airport): Entity => {
  return new Entity({
    position: Cartesian3.fromDegrees(a.longitude, a.latitude, 20),
    billboard: {
      heightReference: HeightReference.CLAMP_TO_TERRAIN,
      disableDepthTestDistance: Number.POSITIVE_INFINITY,
      image: airportImage(a),
      width: 48,
      height: 48,
      scaleByDistance: billboardScaleByDistance,
      translucencyByDistance: billboardTranslucencyByDistance,
    },
    name: a.name,
    label: {
      text: airportLabel(a),
      font: 'bold 16pt "Open Sans", sans-serif',
      verticalOrigin: VerticalOrigin.TOP,
      pixelOffset: new Cartesian2(0, 28),
      heightReference: HeightReference.CLAMP_TO_TERRAIN,
      disableDepthTestDistance: Number.POSITIVE_INFINITY,
      // disableDepthTestDistance: 1e5,
      scaleByDistance: labelScaleByDistance,
      translucencyByDistance: labelTranslucencyByDistance,
      // distanceDisplayCondition: a.icao.length > 0 ? new DistanceDisplayCondition(1.2e10, 1.2e10) : new DistanceDisplayCondition(1.2e2, 1.2e6),
      fillColor: Color.WHITE,
      outlineColor: Color.BLACK,
      outlineWidth: 1,
      style: LabelStyle.FILL_AND_OUTLINE,
    },
    properties: {
      airportDetails: null,
      entityType: 'AIRPORT',
    },
  });
};

export const createRoutePointEntity = (
  pos: LatLng,
  routeIsLocked: boolean
): Entity => {
  return new Entity({
    position: Cartesian3.fromDegrees(pos.longitude, pos.latitude),
    billboard: {
      heightReference: HeightReference.CLAMP_TO_TERRAIN,
      disableDepthTestDistance: 1e6,
      image: routePoint,
      width: routeIsLocked ? 20 : 48,
      height: routeIsLocked ? 20 : 48,
      scaleByDistance: billboardScaleByDistance,
      translucencyByDistance: billboardTranslucencyByDistance,
    },
    name: '',
    label: {
      text: `${pos.latitude.toFixed(3)},${pos.longitude.toFixed(3)}`,
      font: 'bold 13pt "Open Sans", sans-serif',
      verticalOrigin: VerticalOrigin.TOP,
      pixelOffset: new Cartesian2(0, 28),
      heightReference: HeightReference.CLAMP_TO_TERRAIN,
      disableDepthTestDistance: 1e6,
      // disableDepthTestDistance: Number.POSITIVE_INFINITY,
      scaleByDistance: labelScaleByDistance,
      translucencyByDistance: labelTranslucencyByDistance,
      fillColor: Color.WHITE,
      outlineColor: Color.BLACK,
      outlineWidth: 1,
      style: LabelStyle.FILL_AND_OUTLINE,
    },
    properties: {
      entityType: 'ROUTE_POINT',
    },
  });
};

export const createRouteEntity = (route: Route): Entity => {
  return new Entity({
    name: 'Route',
    polyline: {
      clampToGround: true,
      // classificationType: ClassificationType.TERRAIN,
      positions: Cartesian3.fromDegreesArray(
        route.path.flatMap((p) => [p.longitude, p.latitude])
      ),
      zIndex: 0,
      width: 10,
      arcType: ArcType.GEODESIC,
      material: Color.fromCssColorString('#2f65bfee'),
      // depthFailMaterial: Color.fromCssColorString('#2f65bfee'),
    },
  });
};

export const createRouteCorridorEntity = (route: Route): Entity => {
  // console.log('createRouteCorridor', { route });
  const buffer = calcBuffer(route.path, route.corridor);
  return new Entity({
    name: 'Buffer',
    polygon: {
      hierarchy: Cartesian3.fromDegreesArray(
        buffer.geometry.coordinates.flat(2)
      ),
      material: Color.fromCssColorString('#00000044'),
    },
  });
};

export const createRouteEntities = (route: Route) => {
  const points = route.path.map((p) => createRoutePointEntity(p));
  const line = createRouteEntity(route);
  const corridor = createRouteCorridorEntity(route);
  return {
    points,
    line,
    corridor,
  };
};

export const screenSpaceCoordToDeg = (viewer: Viewer, position: Cartesian2) => {
  const scenePos = viewer.scene.pickPosition(position);
  // console.log(scenePos);

  // Only works with globe = true
  // const ray = viewer.camera.getPickRay(position) as Ray;
  // const globePos = viewer.scene.globe.pick(ray, viewer.scene);
  // console.log(globePos);

  const earth = Ellipsoid.WGS84;
  const pos = earth.cartesianToCartographic(scenePos);
  // console.log(pos);

  // console.log(Cartesian3.fromRadians(pos.longitude, pos.latitude));
  const degPos = {
    latitude: Math.toDegrees(pos.latitude),
    longitude: Math.toDegrees(pos.longitude),
  };

  return degPos;
};
