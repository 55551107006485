import { MapSettings } from '../types/types';
import { RouteState } from './state';

export const defaultMapSettings: MapSettings = {
  mapType: 'default',
  showMarkers: {
    cameraSites: true,
    airports: false,
    weatherStations: false,
    vfr: true,
    mvfr: true,
    ifr: true,
    lifr: true,
    noFlightCategory: false,
    aircraft: true,
  },
};

export const defaultRouteState: RouteState = {
  editing: false,
  locked: false,
  name: undefined,
  isNewRoute: false,
  // totalDistanceMi: 0,
  route: {
    path: [],
    corridor: 35,
  },
};
